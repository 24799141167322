import AccessTokenProvider from './AccessTokenProvider';

const uiFrameAccessTokenProvider: AccessTokenProvider = async () => {
  // The store module will initialize a lot of dependencies, including AppInsights. Until we have dependency injection
  // throughout the code base, we need to use a dynamic import to only load the module if needed.
  const store = (await import(/* webpackMode: "eager" */ '../../store/root.store')).default;
  const storeJwt = store.getState().uiFrame.jwt;
  return storeJwt;
};
export default uiFrameAccessTokenProvider;
