import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../core/store/root.store';
import { UpdateRow } from '../types/frontend/update-row';
import { PaginationState, RowSelectionState, SortingState } from '@tanstack/react-table';

export interface WindowsUpdatesSelectorState {
  selectedUpdates: UpdateRow[];
  pagination: PaginationState;
  sorting: SortingState;
  searchText: string;
  rowSelection: RowSelectionState;
  showSecurityUpdatesOnly: boolean;
}

const initialState: WindowsUpdatesSelectorState = {
  selectedUpdates: [],
  pagination: {
    pageIndex: 0,
    pageSize: 10
  },
  sorting: [],
  searchText: '',
  rowSelection: {},
  showSecurityUpdatesOnly: false
};

export const windowsUpdatesSlice = createSlice({
  name: 'windowsUpdates',
  initialState,
  reducers: {
    setSelectedUpdates: (state, action: PayloadAction<UpdateRow[]>) => {
      state.selectedUpdates = action.payload;
    },
    addSelectedUpdates: (state, action: PayloadAction<UpdateRow[]>) => {
      state.selectedUpdates = [...state.selectedUpdates, ...action.payload];
    },
    setPagination: (state, action: PayloadAction<PaginationState>) => {
      state.pagination = action.payload;
    },
    setSorting: (state, action: PayloadAction<SortingState>) => {
      state.sorting = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setRowSelection: (state, action: PayloadAction<RowSelectionState>) => {
      state.rowSelection = action.payload;
    },
    setShowSecurityUpdatesOnly: (state, action: PayloadAction<boolean>) => {
      state.showSecurityUpdatesOnly = action.payload;
    },
    resetTable: () => initialState
  }
});

export const selectSelectedUpdates = (state: RootState): UpdateRow[] => state.windowsUpdates.selectedUpdates;
export const selectPagination = (state: RootState): PaginationState => state.windowsUpdates.pagination;
export const selectSorting = (state: RootState): SortingState => state.windowsUpdates.sorting;
export const selectSearchText = (state: RootState): string => state.windowsUpdates.searchText;
export const selectRowSelection = (state: RootState): RowSelectionState => state.windowsUpdates.rowSelection;
export const selectShowSecurityUpdatesOnly = (state: RootState): boolean =>
  state.windowsUpdates.showSecurityUpdatesOnly;

export const {
  setSelectedUpdates,
  addSelectedUpdates,
  setPagination,
  setRowSelection,
  setSearchText,
  setSorting,
  setShowSecurityUpdatesOnly,
  resetTable
} = windowsUpdatesSlice.actions;

export const { reducer: windowsUpdatesReducer } = windowsUpdatesSlice;
