import useSwr, { SWRResponse } from 'swr';
import http from '../../core/services/http/default-http-client';
import { combineUrl } from '../../core/utilities/utilities';
import { environment } from '../../environments/environment';
import { UpdateJobItem, UpdateJobItemDto, UpdateJobItemSchema } from '../models/update-job-item';
import { jobsListCacheKey } from '../models/cache';
import { useUpdateJobsCreatedLiveData } from './use-update-jobs-created-live-hook';
import { useUpdateJobsChangedLiveData } from './use-update-jobs-changed-live-hook';
import { HostRunStatus } from '@goto/remote-execution';
import { mapHostRunStatusCountToJobStatus } from '../mappers/device-job-status.mapper';
import { z } from 'zod';
import logger from '../../core/services/logger.service';
import { PolicyHistory, useUpdatePolicies } from './use-update-policies';

const UpdateJobItemArray = z.array(UpdateJobItemSchema);

const useActiveAndPastJobs = (): SWRResponse<UpdateJobItemDto[], unknown> =>
  useSwr(jobsListCacheKey, async () =>
    UpdateJobItemArray.parse(await http.get<unknown>(combineUrl(environment.frontendApiUrl, 'jobs/all')))
  );

export const useUpdateJobs = (): { jobs: UpdateJobItem[]; error?: unknown; loading: boolean } => {
  useUpdateJobsCreatedLiveData();
  const liveData = useUpdateJobsChangedLiveData();
  const { error, data: newData } = useActiveAndPastJobs();
  const { policies, loading } = useUpdatePolicies();
  if (error) logger.logError(error as Error);

  // let's combine the data returned by the API and the live one
  const data: UpdateJobItemDto[] | undefined = newData
    ? newData.map(item => ({
        ...item,
        ...liveData[item.id],
        status: mapHostRunStatusCountToJobStatus(item.hostRunStatusCounts)
      }))
    : undefined;

  const jobs = data ? data.map(convertJobDtoToUpdateJobItem(policies)) : [];

  return { error, jobs, loading: !data || loading };
};

const convertJobDtoToUpdateJobItem =
  (policies: PolicyHistory[]) =>
  (dto: UpdateJobItemDto): UpdateJobItem => ({
    ...dto,
    scheduledAt: new Date(dto.scheduledAt),
    finishedAt: dto.finishedAt ? new Date(dto.finishedAt) : undefined,
    policyName: policies.find((policy: PolicyHistory) => policy.jobIds.includes(dto.id))?.name ?? '',
    updateCount:
      (dto?.osUpdateInfo?.mandatoryUpdateCount ?? 0) +
      +(dto?.osUpdateInfo?.optionalUpdateCount ?? 0) +
      (dto?.appUpdateInfo?.count ?? 0),
    jobStatus: dto.isScheduled
      ? dto.status === HostRunStatus.Pending
        ? 'scheduled'
        : dto.status === HostRunStatus.Running
        ? 'running'
        : 'completed'
      : dto.finishedAt
      ? 'completed'
      : 'running'
  });
