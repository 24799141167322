import { ReactElement } from 'react';
import { UpdateRow } from '../../types/frontend/update-row';
import { t } from '../../../i18n/i18n';
import GoToResolveAppLink from '../../../core/components/GoToResolveAppLink';

function DeviceUILinkCellRenderer(data: UpdateRow): ReactElement | null {
  const deviceCount = data.availableOnDevicesCount;
  const updateId = data.id;
  const translationKey =
    deviceCount === 1 ? 'windowsUpdatesGrid.grid.headers.device' : 'windowsUpdatesGrid.grid.headers.devices';

  return (
    <GoToResolveAppLink
      route={{
        path: '/devices/individual-devices?filters=:filters',
        params: { filters: JSON.stringify({ availableUpdates: updateId }) }
      }}>
      {`${deviceCount} ${t(translationKey)}`}
    </GoToResolveAppLink>
  );
}

export default DeviceUILinkCellRenderer;
