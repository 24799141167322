import { ReactElement } from 'react';
import { CellText } from './CellText';
import { WindowsUpdatesCategory } from '../../types/backend/update';

function CategoriesCellRenderer(categories: WindowsUpdatesCategory[]): ReactElement {
  return (
    <CellText>
      {categories.map((c: WindowsUpdatesCategory, index: number) =>
        c.title ? (
          <span key={c.title}>
            {c.title}
            {index !== categories.length - 1 && ', '}
          </span>
        ) : (
          ''
        )
      )}
    </CellText>
  );
}

export default CategoriesCellRenderer;
