import { ReactElement, useEffect, useState } from 'react';
import { Button, Chip } from '@getgo/chameleon-web-react-wrapper';
import styles from './WindowsUpdatesSelector.module.scss';
import { navigate } from '../../../core/services/navigation.service';
import WindowsUpdatesGrid from './windows-updates-grid/WindowsUpdatesGrid';
import usePatchviewDataService from '../../services/usePatchviewDataService';
import { PaginationState, RowSelectionState, SortingState, Updater } from '@tanstack/react-table';
import useSwr from 'swr';
import { PatchviewDataService } from '../../services/PatchviewDataService';
import { useAppDispatch, useAppSelector } from '../../../core/store/root.store';
import {
  addSelectedUpdates,
  selectPagination,
  selectRowSelection,
  selectSearchText,
  selectSelectedUpdates,
  selectShowSecurityUpdatesOnly,
  selectSorting,
  setPagination,
  setRowSelection,
  setSearchText,
  setSelectedUpdates,
  setShowSecurityUpdatesOnly,
  setSorting
} from '../../state/windows-updates-selector-slice';
import { UpdateRow } from '../../types/frontend/update-row';
import { UpToDate } from '../up-to-date/UpToDate';
import { ErrorFallback } from '../error-fallback/ErrorFallback';
import logger from '../../../core/services/logger.service';
import { TrackedButton } from '../../../core/components/tracking/TrackedComponents';
import { PatchViewLoadedEvent, PatchViewUpdatesSelectedEvent } from '../../../core/models/UserTrackingEvents';
import trackingService from '../../../core/services/tracking/tracking.service';
import useCommonDeviceDataService from '../../services/common-device-data-service/useCommonDeviceDataService';
import useFirstVisitFlag from '../../hooks/useFirstVisitFlag';
import { NoUpdates } from '../no-updates/NoUpdates';
import { t } from '../../../i18n/i18n';
import { CloseIcon } from '@getgo/chameleon-icons/react';
import { selectNavigationRoute } from '../../../ui-frame/state/ui-frame.slice';
import { useSearchParams } from 'react-router-dom';

function WindowsUpdatesSelector(): ReactElement {
  const { patchviewDataService, isPatchviewDataServiceReady } = usePatchviewDataService();
  const { commonDeviceDataService, isCommonDeviceDataServiceReady } = useCommonDeviceDataService();

  const [searchParams] = useSearchParams();
  const params = searchParams.get('params');

  const dispatch = useAppDispatch();
  const sorting = useAppSelector(selectSorting);
  const selectedUpdates = useAppSelector(selectSelectedUpdates);
  const rowSelection = useAppSelector(selectRowSelection);
  const searchText = useAppSelector(selectSearchText);
  const pagination = useAppSelector(selectPagination);
  const showSecurityUpdatesOnly = useAppSelector(selectShowSecurityUpdatesOnly);
  const [hasVisitedPatchviewBefore, setHasVisitedPatchviewBefore] = useFirstVisitFlag();
  const navigationRoute = useAppSelector(selectNavigationRoute);
  const [urlDeviceIds, setUrlDeviceIds] = useState<string[]>([]);

  useEffect(() => {
    if (params) {
      navigate({ path: '/' });
      const parsedParams = JSON.parse(params ? params : '');
      setUrlDeviceIds(parsedParams.deviceIds || []);
    }
  }, [params]);

  useEffect(() => {
    try {
      trackingService.trackUserEvent(new PatchViewLoadedEvent());
    } catch (error) {
      logger.logError(error as Error | string);
    }
  }, []);

  useEffect(() => {
    const path = showSecurityUpdatesOnly
      ? ('/windows-updates#security-only' as '/windows-updates')
      : ('/windows-updates#all-updates' as '/windows-updates');
    if (navigationRoute.includes('/windows-updates')) {
      navigate({ path });
    }
  }, [showSecurityUpdatesOnly]);

  useEffect(() => {
    if (navigationRoute.includes('/windows-updates#security-only')) {
      dispatch(setShowSecurityUpdatesOnly(true));
    }
    if (navigationRoute.includes('/windows-updates#all-updates')) {
      dispatch(setShowSecurityUpdatesOnly(false));
    }
  }, []);

  const fetchAvailableUpdates = async (patchviewDataService: PatchviewDataService): Promise<UpdateRow[]> => {
    if (!patchviewDataService) {
      throw new Error('PatchviewDataService is not initialized');
    }
    const { availableUpdates } = await patchviewDataService.getAvailableUpdates(
      pagination,
      sorting,
      showSecurityUpdatesOnly,
      searchText,
      urlDeviceIds
    );
    return availableUpdates;
  };

  const fetchUpdatesCount = async (patchviewDataService: PatchviewDataService): Promise<number> => {
    if (!patchviewDataService) {
      throw new Error('PatchviewDataService is not initialized');
    }
    return patchviewDataService.getTotalAvailableUpdatesCount(showSecurityUpdatesOnly, searchText, urlDeviceIds);
  };

  const {
    data: updates,
    error: updatesError,
    isLoading: updatesIsLoading
  } = useSwr(
    isPatchviewDataServiceReady
      ? [
          'getAvailableUpdates',
          pagination.pageIndex,
          pagination.pageSize,
          sorting,
          showSecurityUpdatesOnly,
          searchText,
          urlDeviceIds
        ]
      : null,
    () => fetchAvailableUpdates(patchviewDataService),
    { shouldRetryOnError: false, use: [], keepPreviousData: true }
  );

  const { data: updatesCount, error: UpdatesCountError } = useSwr(
    isPatchviewDataServiceReady ? ['getAvailableUpdates', showSecurityUpdatesOnly, searchText, urlDeviceIds] : null,
    () => fetchUpdatesCount(patchviewDataService),
    { shouldRetryOnError: false, use: [], keepPreviousData: true }
  );

  const {
    data: deviceIds,
    error: cdsError,
    isLoading: cdsIsLoading
  } = useSwr(isCommonDeviceDataServiceReady ? ['getDeviceIds'] : null, () => commonDeviceDataService.getDeviceIds(), {
    shouldRetryOnError: false,
    use: []
  });

  const handlePaginationChange = (updaterOrValue: Updater<PaginationState>): void => {
    if (typeof updaterOrValue === 'function') {
      const updated = updaterOrValue(pagination);
      dispatch(setPagination(updated));
    } else {
      setPagination(updaterOrValue);
    }
  };

  const handleRowSelectionChange = (updaterOrValue: Updater<RowSelectionState>): void => {
    if (typeof updaterOrValue !== 'function') return;

    const diff = updaterOrValue({});
    const updated = updaterOrValue(rowSelection);

    const hasNewKeys = !!Object.keys(diff).length;

    if (hasNewKeys) {
      handleNewUpdateKey(updated);
    } else {
      handleRemovedKey(updated);
    }

    dispatch(setRowSelection(updated));
  };

  const handleNewUpdateKey = (updated: RowSelectionState): void => {
    const updateKeys = Object.keys(updated);

    const newUpdateKeys = updateKeys.filter(
      update => !selectedUpdates.some(selectedUpdate => selectedUpdate.uuid === update)
    );

    const newSelectedUpdates = newUpdateKeys
      .map(newUpdateKey => updates?.find(update => update.uuid === newUpdateKey))
      .filter(Boolean) as UpdateRow[];

    dispatch(addSelectedUpdates(newSelectedUpdates));
  };

  const handleRemovedKey = (updated: RowSelectionState): void => {
    const updatedKeys = Object.keys(updated);
    const removedKeys = Object.keys(rowSelection).filter(key => !updatedKeys.includes(key));
    const updatedSelectedUpdates = selectedUpdates.filter(update => !removedKeys.includes(update.uuid));
    if (updatedSelectedUpdates) {
      dispatch(setSelectedUpdates(updatedSelectedUpdates));
    }
  };

  const handleSortingChange = (updaterOrValue: Updater<SortingState>): void => {
    if (typeof updaterOrValue === 'function') {
      dispatch(setSorting(updaterOrValue(sorting)));
    } else {
      setSorting(updaterOrValue);
    }
  };

  const handleFilterChange = (showSecurityUpdatesOnly: boolean): void => {
    dispatch(setShowSecurityUpdatesOnly(showSecurityUpdatesOnly));
    dispatch(setPagination({ ...pagination, pageIndex: 0 }));
  };

  const handleDeselectAll = (): void => {
    dispatch(setRowSelection({}));
    dispatch(setSelectedUpdates([]));
  };

  const pageCount = (): number | undefined => {
    if (updatesCount) {
      return Math.ceil(updatesCount / pagination.pageSize);
    }
  };

  const renderChips = (): ReactElement => {
    return (
      <div className={styles.chipsContainer}>
        <Chip size="small" active={!showSecurityUpdatesOnly} onClick={() => handleFilterChange(false)}>
          {t('windowsUpdatesSelector.showAllUpdates')}
        </Chip>
        <Chip size="small" active={showSecurityUpdatesOnly} onClick={() => handleFilterChange(true)}>
          {t('windowsUpdatesSelector.showSecurityUpdates')}
        </Chip>
      </div>
    );
  };

  const isUpToDateAndHasDevicesWithNoFilters =
    deviceIds?.length &&
    !updatesError &&
    !updatesIsLoading &&
    !updates?.length &&
    !showSecurityUpdatesOnly &&
    !searchText;

  const showAsLoading = cdsIsLoading || updatesIsLoading || !updates;

  if (deviceIds?.length === 0) {
    return <NoUpdates></NoUpdates>;
  }

  if (isUpToDateAndHasDevicesWithNoFilters) {
    if (!hasVisitedPatchviewBefore) {
      setHasVisitedPatchviewBefore(true);
      return <NoUpdates hasDevices={true}></NoUpdates>;
    }
    return <UpToDate></UpToDate>;
  }

  if (cdsError || updatesError || UpdatesCountError) {
    const error = updatesError ?? UpdatesCountError ?? cdsError;
    logger.logError(error);

    return <ErrorFallback></ErrorFallback>;
  }

  const renderDeselectButton = (): ReactElement | undefined => {
    if (selectedUpdates.length > 0) {
      return (
        <div>
          <Button
            className={styles.updateButton}
            variant="tertiary"
            onClick={handleDeselectAll}
            trailingIcon={<CloseIcon />}>
            {t('windowsUpdatesSelector.updatesSelected', { selectedUpdates: selectedUpdates.length })}
          </Button>
        </div>
      );
    }
  };

  return (
    <>
      <div className={styles.buttonContainer}>
        <TrackedButton
          trackingEvent={new PatchViewUpdatesSelectedEvent(Object.values(rowSelection).length)}
          variant="primary"
          disabled={!Object.values(rowSelection).length}
          onClick={() => {
            navigate({ path: '/schedule' });
          }}>
          {t('windowsUpdatesSelector.installUpdates')}
        </TrackedButton>
      </div>

      <WindowsUpdatesGrid
        data={showAsLoading ? updates?.map(() => null) ?? [null, null, null] : updates}
        onPaginationChange={handlePaginationChange}
        pagination={pagination}
        onRowSelectionChange={handleRowSelectionChange}
        rowSelection={rowSelection}
        onSortingChange={handleSortingChange}
        sorting={sorting}
        globalFilterEnabled={true}
        onGlobalFilterChange={(searchString: string) => {
          dispatch(setSearchText(searchString));
          dispatch(setPagination({ ...pagination, pageIndex: 0 }));
        }}
        searchText={searchText}
        state={{ pagination, rowSelection, sorting }}
        isLoading={showAsLoading}
        renderDeselectButton={renderDeselectButton()}
        renderSlot={renderChips()}
        pageCount={pageCount()}
        totalRowCount={updatesCount}></WindowsUpdatesGrid>
    </>
  );
}

export default WindowsUpdatesSelector;
