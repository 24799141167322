import SoftwareInventory from '../models/SoftwareInventory';
import useSwr, { KeyedMutator } from 'swr';
import ApplicationService from '../services/ApplicationService';
import { useInjection } from 'inversify-react';

const useDeviceApplicationUpdates = (
  deviceId: string
): { data?: SoftwareInventory; error?: Error; mutate: KeyedMutator<SoftwareInventory>; isLoading: boolean } => {
  const applicationService = useInjection(ApplicationService);
  return useSwr([deviceId, 'ApplicationService', 'getDeviceUpdates'], ([deviceId]) => {
    return applicationService.getDeviceUpdates(deviceId);
  });
};

export default useDeviceApplicationUpdates;
