import {
  Button,
  SidePanel,
  SidePanelActions,
  Typography,
  Checkbox,
  TextField
} from '@getgo/chameleon-web-react-wrapper';
import { ColumnFilter, ColumnFiltersState } from '@tanstack/react-table';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './JobHistoryFilterPanel.module.scss';
import { CheckboxComponent, TextFieldComponent } from '@getgo/chameleon-web';

interface JobHistoryFilterPanelProps {
  isOpen: boolean;
  filtersState: ColumnFiltersState;
  onClose?: () => void;
  onFiltersChange?: (filters: ColumnFiltersState) => void;
}

export default function JobHistoryFilterPanel({
  isOpen,
  filtersState,
  onClose,
  onFiltersChange
}: JobHistoryFilterPanelProps): ReactElement {
  const getValue = <T,>(id: string): T | null => (filtersState.find(f => f.id === id)?.value as T) ?? null;

  const { t } = useTranslation();
  const [progress, setProgress] = useState([...(getValue<string>('hostRunStatusCounts') ?? [])]);
  const [name, setName] = useState(getValue<string>('displayName') ?? '');
  const [policyName, setPolicyName] = useState(getValue<string>('policyName') ?? '');
  const [jobStatus, setJobStatus] = useState([...(getValue<string>('jobStatus') ?? [])]);
  const [updates, setUpdates] = useState([...(getValue<string>('updateCount') ?? [])]);
  const [deviceCount] = useState(getValue<string>('deviceCount') ?? '0');

  useEffect(() => {
    setProgress([...(getValue<string>('hostRunStatusCounts') ?? [])]);
    setName(getValue<string>('displayName') ?? '');
    setPolicyName(getValue<string>('policyName') ?? '');
    setJobStatus([...(getValue<string>('jobStatus') ?? [])]);
    setUpdates([...(getValue<string>('updateCount') ?? [])]);
  }, [filtersState]);

  function mapToFilterState(): ColumnFiltersState {
    const deduplicate = (filter: ColumnFilter): ColumnFilter => ({
      id: filter.id,
      value: [...new Set(filter.value as string[])]
    });

    return [
      progress.length > 0 ? { id: 'hostRunStatusCounts', value: progress } : null,
      name ? { id: 'displayName', value: name } : null,
      policyName ? { id: 'policyName', value: policyName } : null,
      jobStatus.length > 0 ? { id: 'jobStatus', value: jobStatus } : null,
      updates.length > 0 ? { id: 'updateCount', value: updates } : null,
      deviceCount !== '0' ? { id: 'deviceCount', value: deviceCount } : null
    ].flatMap(f => {
      if (!f) return [];
      return Array.isArray(f.value) ? deduplicate(f) : f;
    });
  }

  function checkboxHandlerFactory(state: string[], setter: (value: string[]) => void) {
    return (item: string) => (e: ChangeEvent<CheckboxComponent>) => {
      if (e.target.checked) {
        setter([...state, item]);
      } else {
        setter(state.filter(s => s !== item));
      }
    };
  }

  const onProgressChange = checkboxHandlerFactory(progress, setProgress);
  const onJobStatusChange = checkboxHandlerFactory(jobStatus, setJobStatus);
  const onUpdatesChange = checkboxHandlerFactory(updates, setUpdates);

  return (
    <SidePanel
      isOpen={isOpen}
      closeLabel="Close"
      onClose={onClose}
      footer={
        <SidePanelActions>
          <Button variant="neutral" onClick={() => onFiltersChange?.([])}>
            {t('jobHistoryGrid.clearFilter')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onFiltersChange?.(mapToFilterState());
            }}>
            {t('jobHistoryGrid.applyFilter')}
          </Button>
        </SidePanelActions>
      }>
      <div className={styles.sidepanelContents}>
        <Typography className={styles.filterCaption} variant="caption-medium-strong">
          {t('jobHistoryGrid.headers.hostRunStatusCounts')}
        </Typography>
        <Checkbox onChange={onProgressChange('finished')} checked={progress.includes('finished')}>
          {t('updateDetails.grid.deviceJobStatus.finished.title')}
        </Checkbox>
        <Checkbox onChange={onProgressChange('failed')} checked={progress.includes('failed')}>
          {t('updateDetails.grid.deviceJobStatus.failed.title')}
        </Checkbox>
        <Checkbox onChange={onProgressChange('cancelled')} checked={progress.includes('cancelled')}>
          {t('updateDetails.grid.deviceJobStatus.cancelled.title')}
        </Checkbox>
        <Checkbox onChange={onProgressChange('running')} checked={progress.includes('running')}>
          {t('updateDetails.grid.deviceJobStatus.running.title')}
        </Checkbox>
        <Checkbox onChange={onProgressChange('pending')} checked={progress.includes('pending')}>
          {t('updateDetails.grid.deviceJobStatus.pending.title')}
        </Checkbox>
        <Typography className={styles.filterCaption} variant="caption-medium-strong">
          {t('jobHistoryGrid.headers.displayName')}
        </Typography>
        <TextField
          placeholder="Search"
          value={name}
          onChange={(e: ChangeEvent<TextFieldComponent>) => setName(e.target.value)}></TextField>
        <Typography className={styles.filterCaption} variant="caption-medium-strong">
          Installed by
        </Typography>
        <TextField
          placeholder="Search"
          value={policyName}
          onChange={(e: ChangeEvent<TextFieldComponent>) => setPolicyName(e.target.value)}></TextField>
        <Typography className={styles.filterCaption} variant="caption-medium-strong">
          {t('jobHistoryGrid.headers.status')}
        </Typography>
        <Checkbox onChange={onJobStatusChange('completed')} checked={jobStatus.includes('completed')}>
          {t('jobHistoryGrid.groups.completed')}
        </Checkbox>
        <Checkbox onChange={onJobStatusChange('running')} checked={jobStatus.includes('running')}>
          {t('jobHistoryGrid.groups.running')}
        </Checkbox>
        <Checkbox onChange={onJobStatusChange('scheduled')} checked={jobStatus.includes('scheduled')}>
          {t('jobHistoryGrid.groups.scheduled')}
        </Checkbox>
        <Typography className={styles.filterCaption} variant="caption-medium-strong">
          {t('jobHistoryGrid.headers.updateCount')}
        </Typography>
        <Checkbox onChange={onUpdatesChange('os')} checked={updates.includes('os')}>
          {t('jobHistoryGrid.updates.os')}
        </Checkbox>
        <Checkbox onChange={onUpdatesChange('app')} checked={updates.includes('app')}>
          {t('jobHistoryGrid.updates.app')}
        </Checkbox>
      </div>
    </SidePanel>
  );
}
