import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getAction } from 'synapse-js/events/navigation';
import { navigate } from '../../core/services/navigation.service';
import { useAppDispatch, useAppSelector } from '../../core/store/root.store';
import { selectDateLocale, selectNavigationRoute } from '../../ui-frame/state/ui-frame.slice';
import { SelectedDeviceIds } from '../components/devices-selector/devices-grid/DevicesGrid';
import { WizardStep } from '../pages/schedule-updates-wizard/ScheduleUpdatesWizard';
import usePatchviewDataService from '../services/usePatchviewDataService';
import { selectSelectedUpdates, setRowSelection, setSelectedUpdates } from '../state/windows-updates-selector-slice';

interface Action {
  deviceIds: string[];
  actionName?: string;
  updateFilter?: string;
}

const SECURITY_UPDATES_ONLY = 'security-updates-only';

const isActionWithDeviceIds = (action: ReturnType<typeof getAction>): action is Action => {
  return 'deviceIds' in action && Array.isArray(action.deviceIds);
};

export const useLoadUpdatesAndDevicesFromActionURL = (
  setBufferedDeviceIdSelections: Dispatch<SetStateAction<SelectedDeviceIds | undefined>>,
  setCurrentIndex: Dispatch<SetStateAction<WizardStep>>
): [boolean, Error | null] => {
  const dispatch = useAppDispatch();
  const selectedUpdates = useAppSelector(selectSelectedUpdates);
  const { patchviewDataService, isPatchviewDataServiceReady } = usePatchviewDataService();
  const [isUpdatesLoading, setIsUpdatesLoading] = useState(false);
  const [error, setError] = useState<null | Error>(null);
  const locale = useAppSelector(selectDateLocale);
  const navigationRoute = useAppSelector(selectNavigationRoute);

  useEffect(() => {
    if (isPatchviewDataServiceReady) {
      try {
        setIsUpdatesLoading(true);

        const action = getAction();
        if (!isActionWithDeviceIds(action)) {
          throw new Error('No deviceIds found in action');
        }
        setCurrentIndex(WizardStep.ReviewAndInstall);

        patchviewDataService
          .getAvailableUpdates(
            { pageIndex: 0, pageSize: 190 },
            [{ id: 'id', desc: true }],
            action.updateFilter === SECURITY_UPDATES_ONLY,
            undefined,
            action.deviceIds,
            locale
          )
          .then(({ availableUpdates: updates }) => {
            dispatch(setSelectedUpdates(updates));
            const selectedRows = Object.fromEntries(updates.map(update => [update.uuid, true]));
            dispatch(setRowSelection(selectedRows));

            // buffering because the device selector is not ready yet and would overwrite our selection on load
            setBufferedDeviceIdSelections({
              deviceIds: action.deviceIds
            });
            setIsUpdatesLoading(false);
          })
          .catch(e => {
            setError(e as Error);
            setIsUpdatesLoading(false);
          });
      } catch (e) {
        if (selectedUpdates.length === 0) {
          navigate({ path: '/' });
        }
        setIsUpdatesLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPatchviewDataServiceReady, navigationRoute]);
  return [isUpdatesLoading, error];
};
