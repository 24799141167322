import { useEffect, useState } from 'react';
import { useInjection } from 'inversify-react';
import { PatchviewDataService } from './PatchviewDataService';
import { useAppSelector } from '../../core/store/root.store';
import { selectJwt } from '../../ui-frame/state/ui-frame.slice';

type UsePatchviewDataServiceReturnType = {
  patchviewDataService: PatchviewDataService;
  isPatchviewDataServiceReady: boolean;
};

const usePatchviewDataService = (): UsePatchviewDataServiceReturnType => {
  const patchviewDataService = useInjection(PatchviewDataService);
  const token = useAppSelector(selectJwt);
  const [isPatchviewDataServiceReady, setIsPatchviewDataServiceReady] = useState(false);

  useEffect(() => {
    if (token) {
      patchviewDataService.setToken(token);
      setIsPatchviewDataServiceReady(true);
    }
  }, [token, patchviewDataService]);

  return { patchviewDataService, isPatchviewDataServiceReady };
};

export default usePatchviewDataService;
