import {
  ArrowLeftOutlinedIcon,
  ArrowRightOutlinedIcon,
  PlayCircleOutlinedIcon,
  RefreshOutlinedIcon
} from '@getgo/chameleon-icons/react';
import { Button, ProgressStep, ProgressStepper, Typography } from '@getgo/chameleon-web-react-wrapper';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../../core/components/snackbar/SnackbarProvider';
import logger from '../../../core/services/logger.service';
import { navigate } from '../../../core/services/navigation.service';

import { CircularProgress, ProgressStepPanel } from '@getgo/chameleon-web-react-wrapper/register';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PatchViewDevicesSelectedEvent } from '../../../core/models/UserTrackingEvents';
import trackingService from '../../../core/services/tracking/tracking.service';
import { useAppDispatch, useAppSelector } from '../../../core/store/root.store';
import { InstallWindowsUpdatesRequest } from '../../../windows-updates/models/install-updates-request';
import UpdateScheduler from '../../../windows-updates/services/UpdateScheduler';
import { SelectedDeviceIds } from '../../components/devices-selector/devices-grid/DevicesGrid';
import DevicesSelector from '../../components/devices-selector/DevicesSelector';
import { ErrorFallback } from '../../components/error-fallback/ErrorFallback';
import ReviewAndInstall from '../../components/review-and-install/ReviewAndInstall';
import { useLoadUpdatesAndDevicesFromActionURL } from '../../hooks/useActionURLFromDevices';
import {
  selectSelectedDeviceIds,
  selectSelectedDevices,
  setDevices,
  setSelectedDeviceIds
} from '../../state/device-selector.slice';
import {
  resetTable,
  selectSelectedUpdates,
  setRowSelection,
  setSelectedUpdates
} from '../../state/windows-updates-selector-slice';
import { formatSelectedDevicesTranslation } from '../../utils/format-selected-devices-translation';
import styles from './ScheduleUpdatesWizard.module.scss';
import { UpdateRow } from '../../types/frontend/update-row';
import { DateTime } from 'luxon';

export enum WizardStep {
  SelectUpdates,
  SelectDevices,
  ReviewAndInstall
}

export default function ScheduleUpdatesPage(): ReactElement {
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const params = searchParams.get('params');

  const [currentIndex, setCurrentIndex] = useState(WizardStep.SelectDevices);

  const [isLoading, setIsLoading] = useState(false);
  const [bufferedDeviceIdSelections, setBufferedDeviceIdSelections] = useState<SelectedDeviceIds>();

  useEffect(() => {
    if (params) {
      navigate({ path: '/schedule' });
      const parsedParams = JSON.parse(params ? params : '');
      setCurrentIndex(parsedParams.wizardStep || WizardStep.SelectDevices);
      const rowSelectionObject: { [key: string]: boolean } = {};
      parsedParams.updates.forEach((update: UpdateRow) => {
        rowSelectionObject[update.uuid] = true;
      });
      dispatch(setSelectedUpdates(parsedParams.updates || []));
      dispatch(setRowSelection(rowSelectionObject));
      dispatch(setSelectedDeviceIds({ deviceIds: parsedParams.deviceIds || [], groupIds: [] }));
      dispatch(setDevices(parsedParams.selectedDevices || []));
      setBufferedDeviceIdSelections({ deviceIds: parsedParams.deviceIds || [] });
    }
  }, [params]);
  const [scheduled, setScheduled] = useState(false);

  const dispatch = useAppDispatch();
  const selectedDevices = useAppSelector(selectSelectedDevices);
  const selectedUpdates = useAppSelector(selectSelectedUpdates);

  // Not all selected updates will make it into the update job, because not all devices might be selected.
  const deviceFilteredSelectedUpdates = selectedUpdates.filter(update =>
    selectedDevices.some(device => (device.patchmanagement?.availableUpdates || []).some(({ id }) => id === update.id))
  );
  const { deviceIds } = useAppSelector(selectSelectedDeviceIds);

  const [isUpdatesLoading, error] = useLoadUpdatesAndDevicesFromActionURL(
    setBufferedDeviceIdSelections,
    setCurrentIndex
  );

  const scheduleDataRef = useRef<InstallWindowsUpdatesRequest>();

  const onScheduleUpdates = async (): Promise<void> => {
    const scheduleDataCurrent = scheduleDataRef.current;

    if (!scheduleDataCurrent) {
      return;
    }

    const scheduleData = {
      ...scheduleDataCurrent,
      devices: scheduled
        ? scheduleDataCurrent?.devices ?? []
        : (scheduleDataCurrent?.devices ?? []).map(device => ({
            ...device,
            timeZone: DateTime.local().zoneName ?? undefined
          }))
    };

    setIsLoading(true);

    try {
      await UpdateScheduler.scheduleWindowsUpdate(scheduleData);
      snackbar.show({
        title: t(`scheduleUpdatesPage.snackbar.updateSuccess`)
      });
      navigate({ path: '/history' });
    } catch (e) {
      logger.logError(e as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackClick = (): void => {
    if (currentIndex === WizardStep.SelectDevices) {
      navigate({ path: '/' });
      return;
    }
    setCurrentIndex(prev => prev - 1);
  };

  const handleNextClick = (): void => {
    if (currentIndex === WizardStep.SelectDevices) {
      try {
        trackingService.trackUserEvent(new PatchViewDevicesSelectedEvent(selectedDevices.length));
      } catch (error) {
        logger.logError(error as Error | string);
      }
    }
    setCurrentIndex(prev => prev + 1);
  };

  const handleStartOverClick = (): void => {
    dispatch(resetTable());
    navigate({ path: '/' });
  };

  const isStepSelected = (arr: WizardStep[]): boolean => {
    return arr.includes(currentIndex);
  };

  const handleStepChange = (e: Event): void => {
    const activeStepIndex = +(e.target as EventTarget & { activeStep: string }).activeStep;

    if (currentIndex !== activeStepIndex) {
      setCurrentIndex(activeStepIndex);
    }
  };

  if (isUpdatesLoading && !selectedDevices.length) {
    return (
      <>
        <div className={styles.loadingContainer}>
          <CircularProgress></CircularProgress>
        </div>
      </>
    );
  }

  if (error) {
    logger.logError(error);
    return (
      <>
        <ErrorFallback></ErrorFallback>
        <div className={styles.actions}>
          <div className={styles.actionsLeft}>
            <Button
              variant="tertiary"
              size="large"
              leadingIcon={<RefreshOutlinedIcon />}
              onClick={handleStartOverClick}>
              {t('scheduleUpdatesPage.actions.startOver')}
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Typography className={styles.title} variant="heading-medium" tag="h1">
        {t(`scheduleUpdatesPage.title`)}
      </Typography>
      <div>
        <ProgressStepper className={styles.progressStepper} activeStep={currentIndex} onStepChange={handleStepChange}>
          <ProgressStep
            badgeValue="1"
            showTooltip={false}
            variant="success"
            description={`
              ${t('scheduleUpdatesPage.progressStepper.selectUpdatesDescription', {
                number: selectedUpdates.length
              })} 
              ${
                deviceFilteredSelectedUpdates.length
                  ? `(${t('scheduleUpdatesPage.progressStepper.applicableUpdatesDescription', {
                      count: deviceFilteredSelectedUpdates.length
                    })})`
                  : ''
              }
            `}>
            {t(`scheduleUpdatesPage.progressStepper.selectUpdates`)}
          </ProgressStep>
          <ProgressStep
            badgeValue="2"
            showTooltip={false}
            selected={isStepSelected([WizardStep.SelectDevices])}
            variant={isStepSelected([WizardStep.SelectDevices]) ? 'neutral' : 'success'}
            description={formatSelectedDevicesTranslation(deviceIds)}>
            {t(`scheduleUpdatesPage.progressStepper.selectDevices`)}
          </ProgressStep>
          <ProgressStep showTooltip={false} badgeValue="3" selected={isStepSelected([WizardStep.ReviewAndInstall])}>
            {t(`scheduleUpdatesPage.progressStepper.reviewAndInstall`)}
          </ProgressStep>
          <ProgressStepPanel></ProgressStepPanel>
          <ProgressStepPanel>
            <DevicesSelector
              selectedUpdates={selectedUpdates}
              bufferedDeviceIdSelections={bufferedDeviceIdSelections}
              setBufferedDeviceIdSelections={setBufferedDeviceIdSelections}></DevicesSelector>
          </ProgressStepPanel>
          <ProgressStepPanel>
            <ReviewAndInstall
              selectedDevices={selectedDevices}
              deviceFilteredSelectedUpdates={deviceFilteredSelectedUpdates}
              scheduleDataRef={scheduleDataRef}
              isScheduled={scheduled}
              setIsScheduled={setScheduled}></ReviewAndInstall>
          </ProgressStepPanel>
        </ProgressStepper>
      </div>
      <div className={styles.actions}>
        <div className={styles.actionsLeft}>
          <Button variant="tertiary" size="large" leadingIcon={<RefreshOutlinedIcon />} onClick={handleStartOverClick}>
            {t('scheduleUpdatesPage.actions.startOver')}
          </Button>
        </div>
        <div className={styles.actionsRight}>
          <Button variant="neutral" size="large" leadingIcon={<ArrowLeftOutlinedIcon />} onClick={handleBackClick}>
            {t('scheduleUpdatesPage.actions.back')}
          </Button>
          {currentIndex === WizardStep.ReviewAndInstall ? (
            <Button
              size="large"
              isLoading={isLoading}
              onClick={onScheduleUpdates}
              trailingIcon={<PlayCircleOutlinedIcon />}
              type="submit">
              {t(`scheduleUpdatesPage.actions.${scheduled ? 'scheduleUpdates' : 'install'}`)}
            </Button>
          ) : (
            <Button
              size="large"
              disabled={deviceIds?.length === 0}
              trailingIcon={<ArrowRightOutlinedIcon />}
              onClick={handleNextClick}>
              {t('scheduleUpdatesPage.actions.nextStep')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
