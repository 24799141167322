import { PropsWithChildren, ReactElement } from 'react';
import { IconButton } from '@getgo/chameleon-web-react-wrapper';
import { RefreshFilledIcon } from '@getgo/chameleon-icons/react';
import { useTranslation } from 'react-i18next';
import styles from './DeviceDetails.module.scss';

type RefreshUpdateStatusButtonProps = {
  thereIsAnOngoingRequest: boolean;
  triggerRevalidation: () => void;
} & PropsWithChildren;

function RefreshUpdateStatusButton({
  thereIsAnOngoingRequest,
  triggerRevalidation
}: RefreshUpdateStatusButtonProps): ReactElement {
  const { t } = useTranslation();
  return (
    <>
      <IconButton
        className={styles.refreshUpdateStatusButton}
        onClick={triggerRevalidation}
        variant="primary"
        isLoading={thereIsAnOngoingRequest}
        disabled={thereIsAnOngoingRequest}
        aria-label="Reload table">
        <RefreshFilledIcon />
      </IconButton>
    </>
  );
}

export default RefreshUpdateStatusButton;
