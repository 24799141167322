import { AppRoutePattern } from '../../models/routes';
import TrackingEvent from '../../models/TrackingEvent';

export const pageViewPrefix = 'Page_Viewed_WindowsUpdates';
export const pageViewTrackingEvents: Record<AppRoutePattern, TrackingEvent> = {
  '*': { name: `${pageViewPrefix}_Devices` },
  '/': { name: `${pageViewPrefix}_Devices` },
  '/windows-updates': { name: `${pageViewPrefix}_Patchview` },
  '/devices/:deviceId/updates/:activeTab': { name: `${pageViewPrefix}_DeviceDetails` },
  '/devices/:deviceId/updates/*': { name: `${pageViewPrefix}_DeviceDetails` },
  '/updates': { name: `${pageViewPrefix}_Jobs` },
  '/devices': { name: `${pageViewPrefix}_Devices` },
  '/updates/:jobId/devices/:deviceId/updates': { name: `${pageViewPrefix}_JobDeviceDetails` },
  '/updates/:jobId/devices/:deviceId/updates/app-jobs-history': { name: `${pageViewPrefix}_JobDeviceDetails` },
  '/updates/:jobId/devices/:deviceId/updates/os-jobs-history': { name: `${pageViewPrefix}_JobDeviceDetails` },
  '/updates/:updateJobId': { name: `${pageViewPrefix}_JobDetails` },
  '/history': { name: `${pageViewPrefix}_History` },
  '/schedule/*': { name: `${pageViewPrefix}_ScheduleUpdate` }
};

export default pageViewTrackingEvents;
