import { useInjection } from 'inversify-react';
import CompanyDeviceUpdatesService from '../services/CompanyDeviceUpdatesService';
import useSwr from 'swr';
import CompanyDeviceUpdateStatus from '../models/CompanyDeviceUpdateStatus';

export default function useCompanyDeviceUpdateStatus(): { data?: CompanyDeviceUpdateStatus[]; error?: Error } {
  const companyDeviceUpdatesService = useInjection(CompanyDeviceUpdatesService);

  return useSwr(
    ['CompanyDeviceUpdatesService', 'getDeviceUpdateStatus'],
    () => companyDeviceUpdatesService.getDeviceUpdateStatus(),
    { refreshInterval: 120000 }
  );
}
