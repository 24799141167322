import { TooltipV3, Typography } from '@getgo/chameleon-web-react-wrapper/register';
import {
  OnChangeFn,
  PaginationState,
  RowSelectionState,
  SortingState,
  TableState,
  createColumnHelper
} from '@tanstack/react-table';
import { PropsWithChildren, ReactElement } from 'react';
import styles from './WindowsUpdatesGrid.module.scss';
import { Checkbox } from '@getgo/chameleon-web-react-wrapper';
import ChameleonGrid from '../../../core/chameleon-grid/ChameleonGrid';
import { UpdateRow } from '../../../types/frontend/update-row';
import WindowsUpdateDescriptionRenderer from '../../../core/chameleon-cell-renderers/WindowsUpdateDescriptionRenderer';
import WindowsReleaseNotesCellRenderer from '../../../core/chameleon-cell-renderers/WindowsReleaseNotesCellRenderer';
import { t } from '../../../../i18n/i18n';
import SeverityCellRenderer from '../../../core/chameleon-cell-renderers/SeverityCellRenderer/SeverityCellRenderer';
import CategoriesCellRenderer from '../../../core/chameleon-cell-renderers/CategoryCellRenderer';
import DeviceUILinkCellRenderer from '../../../core/chameleon-cell-renderers/DeviceUILinkCellRenderer';
import TextCellSkeleton from '../../../core/chameleon-cell-renderers/TextCellSkeleton';

type Props = {
  data: (UpdateRow | null)[];
  onPaginationChange?: OnChangeFn<PaginationState>;
  pagination: PaginationState;
  onRowSelectionChange: OnChangeFn<RowSelectionState>;
  rowSelection: RowSelectionState;
  onSortingChange: OnChangeFn<SortingState>;
  sorting: SortingState;
  state?: Partial<TableState>;
  globalFilterEnabled: boolean;
  onGlobalFilterChange: OnChangeFn<any>;
  searchText: string;
  renderSlot?: ReactElement;
  renderDeselectButton?: ReactElement;
  isLoading: boolean;
  pageCount?: number;
  totalRowCount?: number;
};

const columnHelper = createColumnHelper<any>();

export default WindowsUpdatesGrid;

function WindowsUpdatesGrid({ isLoading, ...props }: Readonly<Props>): ReactElement {
  function CellText({ children }: Readonly<PropsWithChildren>): ReactElement {
    return (
      <Typography variant="caption-medium" className={styles.cellText} lineClamp={2}>
        {children}
      </Typography>
    );
  }

  const columns = [
    columnHelper.accessor('select', {
      enableSorting: false,
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          onClick={() => {
            table.toggleAllRowsSelected();
          }}></Checkbox>
      ),
      cell: isLoading
        ? () => <Checkbox checked={false} disabled />
        : ({ row }) => (
            <Checkbox
              checked={row.getIsSelected()}
              onClick={() => {
                row.toggleSelected();
              }}></Checkbox>
          )
    }),
    columnHelper.accessor('title', {
      header: () => t('windowsUpdatesGrid.grid.headers.name'),
      cell: isLoading
        ? () => <TextCellSkeleton width={250} />
        : props => (
            <div id="full-name-trigger">
              {WindowsUpdateDescriptionRenderer(props.row.original)}
              <TooltipV3 triggerId="full-name-trigger">{props.getValue()}</TooltipV3>
            </div>
          ),
      maxSize: 400
    }),
    columnHelper.accessor('categories', {
      header: () => t('windowsUpdatesGrid.grid.headers.category'),
      cell: isLoading ? () => <TextCellSkeleton width={200} /> : props => CategoriesCellRenderer(props.getValue())
    }),
    columnHelper.accessor('severity', {
      header: () => t('windowsUpdatesGrid.grid.headers.severity'),
      cell: isLoading
        ? () => <TextCellSkeleton width={100} />
        : props => <CellText>{SeverityCellRenderer(props.getValue())}</CellText>
    }),
    columnHelper.accessor('availableOnDevicesCount', {
      header: () => t('windowsUpdatesGrid.grid.headers.devices'),
      cell: isLoading ? () => <TextCellSkeleton width={80} /> : props => DeviceUILinkCellRenderer(props.row.original)
    }),
    columnHelper.accessor('kbArticleId', {
      header: () => t('windowsUpdatesGrid.grid.headers.kbNumber'),
      cell: isLoading
        ? () => <TextCellSkeleton width={80} />
        : props => WindowsReleaseNotesCellRenderer(props.getValue())
    })
  ];

  return (
    <ChameleonGrid
      classes={{ row: styles.row }}
      defaultColumn={{ size: undefined }}
      columns={columns}
      getRowId={row => row?.uuid}
      {...props}
    />
  );
}
