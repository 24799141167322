import { Typography } from '@getgo/chameleon-web-react-wrapper/register';
import { CellContext, Row, createColumnHelper } from '@tanstack/react-table';
import { PropsWithChildren, ReactElement, Fragment, useState, useMemo } from 'react';
import styles from './ReviewAndInstallGrid.module.scss';
import ChameleonGrid from '../../../core/chameleon-grid/ChameleonGrid';
import { ChevronDownIcon, ChevronUpIcon } from '@getgo/chameleon-icons/react';
import clsx from 'clsx';
import { t } from '../../../../i18n/i18n';
import { AdaptedDevice } from 'devices-component/types/adapted-device';
import { UpdateRow } from '../../../types/frontend/update-row';
import logger from '../../../../core/services/logger.service';

type ReviewAndInstallRow = {
  updateName: UpdateRow['title'];
  devices: AdaptedDevice['deviceName'][];
};

const columnHelper = createColumnHelper<ReviewAndInstallRow>();

type ReviewAndInstallProps = {
  selectedDevices: AdaptedDevice[];
  deviceFilteredSelectedUpdates: UpdateRow[];
};

function ReviewAndInstallGrid({ selectedDevices, deviceFilteredSelectedUpdates }: ReviewAndInstallProps): ReactElement {
  const [expandedDetailRows, setExpandedDetailRows] = useState<{ [key: string]: boolean }>({});

  const handleOnRowClicked = (event: Row<any>): void => {
    setExpandedDetailRows(prevExpandedRows => ({
      ...prevExpandedRows,
      [event.id]: !prevExpandedRows[event.id]
    }));
  };

  const transformUpdatesToUpdatesWithDevicesRows = (
    selectedDevices: AdaptedDevice[] | undefined,
    selectedUpdates: UpdateRow[]
  ): ReviewAndInstallRow[] => {
    if (!selectedDevices) {
      return [];
    }
    // TODO: update synapse-common-device-to version 667 to get types to replace any.
    const reviewAndInstallRows = selectedUpdates
      .map(update => {
        const devicesForUpdate = selectedDevices
          .filter(
            ({ patchmanagement }: any) =>
              patchmanagement?.availableUpdates.some((availableUpdate: any) => availableUpdate.id === update.id)
          )
          .filter(({ deviceName, id }) => {
            if (!deviceName) {
              logger.logWarning(`Unexpected: undefined name for device id: ${id}`);
            }
            return deviceName !== undefined;
          })
          .map(({ deviceName }) => deviceName);

        return {
          updateName: update.title,
          devices: devicesForUpdate
        };
      })
      .filter(row => row.devices.length > 0);

    return reviewAndInstallRows;
  };

  const reviewAndInstallRows = useMemo(
    () => transformUpdatesToUpdatesWithDevicesRows(selectedDevices, deviceFilteredSelectedUpdates),
    [selectedDevices, deviceFilteredSelectedUpdates]
  );

  function CellText({ children }: PropsWithChildren): ReactElement {
    return (
      <Typography variant="caption-medium" className={styles.cellText} lineClamp={3}>
        {children}
      </Typography>
    );
  }

  const updateNameCellRenderer = (props: CellContext<ReviewAndInstallRow, string>): ReactElement => {
    return (
      <div className={clsx(expandedDetailRows[props.row.id] && styles.updateNameCellExpanded)}>
        <CellText>{props.getValue()}</CellText>
        <CellText>{expandedDetailRows[props.row.id] ? props.row.original.devices.join(', ') : ''}</CellText>
      </div>
    );
  };

  const devicesCellRenderer = (props: CellContext<ReviewAndInstallRow, any[]>): ReactElement => {
    return (
      <div
        className={clsx(expandedDetailRows[props.row.id] && styles.devicesCellExpanded, styles.devicesCellContainer)}>
        <CellText>{props.getValue().length}</CellText>
      </div>
    );
  };

  const chevronRenderer = (props: CellContext<ReviewAndInstallRow, any[]>): ReactElement => {
    return (
      <div className={clsx(expandedDetailRows[props.row.id] && styles.chevronCellExpanded)}>
        {!expandedDetailRows[props.row.id] ? (
          <ChevronDownIcon class={styles.chevronIcon}></ChevronDownIcon>
        ) : (
          <ChevronUpIcon class={styles.chevronIcon}></ChevronUpIcon>
        )}
      </div>
    );
  };

  const columns = [
    columnHelper.accessor('updateName', {
      header: () => t('reviewAndInstallGrid.grid.name'),
      cell: updateNameCellRenderer
    }),
    columnHelper.accessor('devices', {
      header: () => t('reviewAndInstallGrid.grid.devices'),
      cell: devicesCellRenderer,
      size: 150
    }),
    columnHelper.accessor(row => row.devices, {
      id: 'chevron',
      header: () => null,
      cell: chevronRenderer,
      size: 70,
      enableSorting: false
    })
  ];

  return (
    <Fragment>
      <div className="summaryTable">
        <ChameleonGrid
          classes={{ row: styles.row, summaryTable: styles.summaryTable }}
          defaultColumn={{ size: undefined }}
          data={reviewAndInstallRows}
          columns={columns}
          manualSorting={false}
          manualPagination={false}
          globalFilterEnabled={false}
          selectionEnabled={false}
          onRowClicked={handleOnRowClicked}
        />
      </div>
    </Fragment>
  );
}

export default ReviewAndInstallGrid;
