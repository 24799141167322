import { InputField, Switch, Typography } from '@getgo/chameleon-web-react-wrapper';
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './ReviewAndInstall.module.scss';
import { DateTime } from 'luxon';
import { SwitchComponent, TextFieldComponent } from '@getgo/chameleon-web';
import { dateTimeInputFormat, getNormalizedCurrentDateStr, normalizeDateTime } from '../../../core/utilities/utilities';
import { t } from '../../../i18n/i18n';
import { usePlaceholderText } from '../../hooks/usePlaceholderText';
import ReviewAndInstallGrid from './review-and-install-grid/ReviewAndInstallGrid';
import { AdaptedDevice } from 'devices-component/types/adapted-device';
import DeviceSnapshot from '../../../core/models/DeviceSnapshot';
import { UpdateRow } from '../../types/frontend/update-row';
import { InstallWindowsUpdatesRequest } from '../../../windows-updates/models/install-updates-request';

type ReviewAndInstallProps = {
  selectedDevices: AdaptedDevice[];
  deviceFilteredSelectedUpdates: UpdateRow[];
  scheduleDataRef: React.MutableRefObject<InstallWindowsUpdatesRequest | undefined>;
  isScheduled: boolean;
  setIsScheduled: Dispatch<SetStateAction<boolean>>;
};

export default function ReviewAndInstall({
  deviceFilteredSelectedUpdates,
  selectedDevices,
  scheduleDataRef,
  isScheduled,
  setIsScheduled
}: ReviewAndInstallProps): ReactElement {
  const placeHolderText = usePlaceholderText(deviceFilteredSelectedUpdates, selectedDevices);

  const [jobName, setJobName] = useState('');
  const [forceReboot, setForceReboot] = useState(false);
  const [scheduleDateTime, setScheduleDateTime] = useState(getNormalizedCurrentDateStr);

  const updatesWithReboot = deviceFilteredSelectedUpdates.filter(update => update.requiresReboot).length;
  const isOfflineDeviceSelected = selectedDevices.some(d => d.status === 'Offline');

  const devices: DeviceSnapshot[] = useMemo(() => {
    return selectedDevices.map(({ id, deviceName, hostName, timeZone }) => ({
      id,
      name: deviceName,
      hostName,
      timeZone: timeZone ?? ''
    }));
  }, [selectedDevices]);

  useEffect(() => {
    scheduleDataRef.current = {
      devices,
      scheduleDateTime,
      isScheduled,
      displayName: jobName.length ? jobName : placeHolderText,
      forceReboot,
      forceRebootMaxDelaysCount: 0,
      installOptionalUpdates: true,
      updateIds: deviceFilteredSelectedUpdates.map(update => update.uuid)
    };
  }, [
    scheduleDateTime,
    isScheduled,
    jobName,
    forceReboot,
    deviceFilteredSelectedUpdates,
    scheduleDataRef,
    selectedDevices,
    devices,
    placeHolderText
  ]);

  const onScheduleDateTimeChange = useCallback(
    (dateString?: string): void => {
      setScheduleDateTime(dateString ?? getNormalizedCurrentDateStr());
    },
    [setScheduleDateTime]
  );

  const onDateTimeChange = useCallback(
    (e: ChangeEvent<TextFieldComponent>): void => {
      if (!e.target.value) {
        return onScheduleDateTimeChange('');
      }

      const normalizedDateTime = normalizeDateTime(e.target.value);
      onScheduleDateTimeChange(normalizedDateTime);
      // we need to set the input element value, to ensure the date/time is within the range of the allowed min/max dates
      e.target.value = normalizedDateTime;
    },
    [onScheduleDateTimeChange]
  );

  const onScheduleModeChanged = useCallback<ChangeEventHandler<SwitchComponent>>(
    ({ target }) => {
      if (!target.checked) {
        setScheduleDateTime(getNormalizedCurrentDateStr);
        setIsScheduled(false);
        return;
      }
      setIsScheduled(true);
    },
    [setIsScheduled]
  );

  const minDate = DateTime.fromJSDate(new Date()).plus({ minutes: 1 }).toJSDate();
  const maxDate = DateTime.fromJSDate(new Date()).plus({ years: 1 }).toJSDate();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.summaryContainer}>
          <Typography variant="body-large-strong"> {t('reviewAndInstall.title')}</Typography>
          <ReviewAndInstallGrid
            selectedDevices={selectedDevices}
            deviceFilteredSelectedUpdates={deviceFilteredSelectedUpdates}></ReviewAndInstallGrid>
        </div>
        <div className={styles.formContainer}>
          <Typography className={styles.title} variant="body-large-strong">
            {t('reviewAndInstall.updateDescription')}
          </Typography>
          <Typography className={styles.title} variant="body-small">
            {t('reviewAndInstall.namingInfo')}
          </Typography>
          <InputField
            maxLength={255}
            fullwidth={true}
            placeholder={placeHolderText}
            onChange={(e: ChangeEvent<TextFieldComponent>) => {
              setJobName(e.target.value);
            }}
            value={jobName}
            helperText={`${jobName.length}/255`}>
            {t('reviewAndInstall.name')}
          </InputField>
          <Switch
            checked={isOfflineDeviceSelected || isScheduled}
            disabled={isOfflineDeviceSelected}
            onChange={onScheduleModeChanged}
            className={styles.switch}>
            <Typography variant="body-medium-strong">{t('reviewAndInstall.scheduleThis')}</Typography>
          </Switch>
          <Typography variant="caption-medium" color="type-color-secondary">
            {t('reviewAndInstall.timeInfo')}
          </Typography>
          {isScheduled && (
            <>
              <InputField
                labelId="date"
                type={'datetime-local'}
                fullwidth
                value={scheduleDateTime}
                onChange={onDateTimeChange}
                min={DateTime.fromJSDate(minDate).toFormat(dateTimeInputFormat)}
                max={DateTime.fromJSDate(maxDate).toFormat(dateTimeInputFormat)}
                className={styles.input}>
                {t('reviewAndInstall.dateAndTime')}
              </InputField>
              <Typography variant="caption-medium" color="type-color-secondary">
                {t('reviewAndInstall.scheduleInfo')}
              </Typography>
            </>
          )}
          <div>
            <Switch
              checked={forceReboot}
              onChange={event => setForceReboot(event.target.checked)}
              className={styles.switch}>
              <Typography variant="body-medium-strong">{t('reviewAndInstall.forceReboot')}</Typography>
            </Switch>
          </div>
          <Typography className={styles.rebootInfo} variant="caption-medium" color="type-color-secondary">
            {t('reviewAndInstall.rebootInfo', {
              number: updatesWithReboot
            })}
          </Typography>
        </div>
      </div>
    </>
  );
}
