import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import logger from 'redux-logger';
import uiFrameReducer from '../../ui-frame/state/ui-frame.slice';
import userReducer from '../state/user.slice';
import allLogsReducer from '../../windows-updates/state/all-logs.slice';
import { windowsUpdatesReducer } from '../../patch-view/state/windows-updates-selector-slice';
import { devicesReducer } from '../../patch-view/state/device-selector.slice';
import manualDeviceScanHistoryReducer from '../state/manual-device-scans.slice';

export const reducers = {
  user: userReducer,
  uiFrame: uiFrameReducer,
  allLogs: allLogsReducer,
  windowsUpdates: windowsUpdatesReducer,
  devices: devicesReducer,
  manualDeviceScanHistory: manualDeviceScanHistoryReducer
};

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// use throughout the app instead of plain `useDispatch` and `useSelector` (https://redux.js.org/usage/usage-with-typescript)
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
