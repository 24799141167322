import { Locale } from '../services/PatchviewDataService';
import { Pagination, UpdateSortCriterion } from '../types/backend/update';

export type AvailableUpdatesInput = {
  pagination: Pagination;
  sort: UpdateSortCriterion[];
  searchText?: string;
  deviceIds?: string[];
  categoryIds?: string[][];
  preferredLocale: Locale | undefined;
};

export type TotalAvailableUpdatesCountInput = {
  searchText?: string;
  deviceIds?: string[];
  categoryIds?: string[][];
  preferredLocale: Locale | undefined;
};

export const getAvailableUpdatesQuery = /* GraphQL */ `
  query getAvailableUpdatesQuery(
    $pagination: Pagination = { offset: 0, limit: 50 }
    $severities: [Severity!]
    $searchText: String
    $sort: [UpdateSortCriterion!]
    $deviceIds: [String!]
    $categoryIds: [[String!]!]
    $preferredLocale: Locale
  ) {
    getAvailableUpdates(
      input: {
        pagination: $pagination
        filters: { severities: $severities, searchText: $searchText, deviceIds: $deviceIds, categoryIds: $categoryIds }
        sort: $sort
        preferredLocale: $preferredLocale
      }
    ) {
      availableUpdates {
        ... on WindowsUpdate {
          id
          uuid
          title
          description
          severity
          software {
            id
            name
            vendor
          }
          kbArticleId
          requiresReboot
          availableOnDevicesCount
          categories {
            description
            uuid
            title
          }
        }
      }
    }
  }
`;
export const totalAvailableUpdatesCountQuery = /* GraphQL */ `
  query getAvailableUpdatesQuery(
    $searchText: String
    $deviceIds: [String!]
    $categoryIds: [[String!]!]
    $preferredLocale: Locale
  ) {
    getAvailableUpdates(
      input: {
        filters: { searchText: $searchText, deviceIds: $deviceIds, categoryIds: $categoryIds }
        preferredLocale: $preferredLocale
      }
    ) {
      totalAvailableUpdatesCount
    }
  }
`;

export const getDevices = /* GraphQL */ `
  query GetDevicesQuery(
    $ids: [String!]
    $offset: Int
    $first: Int
    $sort: Sort
    $verified: Boolean
    $deviceGroupIds: [String!]
    $premium: Boolean
    $platforms: [Platform!]
    $statuses: [StatusFilter!]
    $labels: [String!]
    $lastAccess: DateFilter
    $lastSeen: DateFilter
    $lastBootTime: DateFilter
    $av: AntiVirusFilters
    $patchmanagement: PatchManagementFilters
    $searchText: String
    $issueSeverities: [DeviceIssueSeverity!]
  ) {
    getDevices(
      input: {
        pagination: { offset: $offset, first: $first, sort: $sort }
        advancedFilters: {
          ids: $ids
          verified: $verified
          deviceGroupIds: $deviceGroupIds
          premium: $premium
          platforms: $platforms
          statuses: $statuses
          labels: $labels
          lastAccess: $lastAccess
          lastSeen: $lastSeen
          lastBootTime: $lastBootTime
          av: $av
          patchmanagement: $patchmanagement
          searchText: $searchText
          issueSeverities: $issueSeverities
        }
      }
    ) {
      id
      premium
      verified
      platform
      deviceGroupId
      inSession
      osName
      hostName
      timeZone
      deviceName
      status
      labels
      clientVersion
      osVersion
      ipAddresses
      lastLoggedInUser {
        userName
      }
      lastAccess
      firstSeen
      lastBootTime
      group {
        name
      }
      patchmanagement {
        availableUpdates {
          id
        }
      }
    }
    getAggregateBy(
      input: {
        advancedFilters: {
          verified: $verified
          deviceGroupIds: $deviceGroupIds
          premium: $premium
          platforms: $platforms
          statuses: $statuses
          labels: $labels
          lastAccess: $lastAccess
          lastBootTime: $lastBootTime
          av: $av
          patchmanagement: $patchmanagement
          searchText: $searchText
          issueSeverities: $issueSeverities
        }
      }
    ) {
      aggregate {
        totalDeviceCount
      }
    }
  }
`;

export const getDeviceIds = /* GraphQL */ `
  query getDevices {
    getDevices {
      id
    }
  }
`;
