import EnvironmentConfig from './EnvironmentConfig';

export const environment: EnvironmentConfig = {
  production: true,
  frontendUrl: '#{frontendUrl}#',
  frontendApiUrl: '#{frontendApiUrl}#',
  policyServiceApiUrl: '#{policyServiceApiUrl}#',
  patchviewApiUrl: '#{patchviewApiUrl}#',
  remoteExecutionFrontendApiUrl: '#{remoteExecutionFrontendApiUrl}#',
  zeroTrustApiUrl: '#{zeroTrustApiUrl}#',
  appInsightsInstrumentationKey: '#{appInsightsInstrumentationKey}#',
  helpdeskApiUrl: '#{helpdeskApiUrl}#',
  commonDeviceServiceUrl: '#{commonDeviceServiceUrl}#',
  agGridLicense: '#{agGridLicense}#'
};
