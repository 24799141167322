import { useEffect, useRef } from 'react';
import useLocalStorage from 'use-local-storage';

function useFirstVisitFlag(): [boolean, (value: boolean) => void] {
  const [hasVisited, setHasVisited] = useLocalStorage('hasVisitedPatchviewBefore', false);
  // prevent the value from being set on every render
  const hasSetFlag = useRef(false);

  useEffect(() => {
    if (!hasSetFlag.current && !hasVisited) {
      setHasVisited(true);
      hasSetFlag.current = true;
    }
  }, [hasVisited, setHasVisited]);

  return [hasVisited, setHasVisited];
}

export default useFirstVisitFlag;
