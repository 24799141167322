import { ReactElement, useState, useEffect } from 'react';
import styles from './NoUpdates.module.scss';
import { t } from '../../../i18n/i18n';
import { LaptopCheckStaticPictogram } from '@getgo/chameleon-pictograms/react';
import { Button, EmptyState } from '@getgo/chameleon-web-react-wrapper/register';
import { navigateToApp } from '../../../core/services/navigation.service';

type NoUpdatesProps = {
  hasDevices?: boolean;
};
export function NoUpdates({ hasDevices }: NoUpdatesProps): ReactElement {
  const title = hasDevices ? t('noUpdates.noDevicesHaveReported') : t('noUpdates.noDevices');

  const navigateToDevices = () => {
    navigateToApp({ path: '/devices' });
  };

  return (
    <div className={styles.container}>
      <>
        <EmptyState
          icon={<LaptopCheckStaticPictogram size="72px" />}
          title={title}
          actions={
            hasDevices ? undefined : (
              <Button size="medium" onClick={navigateToDevices}>
                {t('noUpdates.addDevice')}
              </Button>
            )
          }>
          {hasDevices ? t('noUpdates.noDevicesHaveReportedDescription') : t('noUpdates.noDevicesDescription')}
        </EmptyState>
      </>
    </div>
  );
}
