import OnlineStatus from '../models/OnlineStatus';
import { useCallback } from 'react';
import { t } from '../../i18n/i18n';
import useIsCapabilityEnabled from './useIsCapabilityEnabled';
import { Capability } from '../../core/models/user-capability';
import UpdateStatus from '../models/UpdateStatus';
import useUpdateInstallCapabilities from './useUpdateInstallCapabilities';

export default function useDisabledUpdateTooltipText(): (
  device?: { onlineStatus: OnlineStatus },
  updateInProgress?: boolean,
  updateStatus?: UpdateStatus
) => string | null {
  const userIsPatchManagementCapable = useIsCapabilityEnabled(Capability.PatchManagement);
  const updateInstallCapabilities = useUpdateInstallCapabilities();

  return useCallback(
    (device, updateInProgress, updateStatus) => {
      if (!device) {
        return null;
      }

      if (!!updateStatus && !updateInstallCapabilities.hasUpdatesToInstall({ updateStatus })) {
        return t('tooltips.noUpdatesToInstall');
      }

      const { onlineStatus } = device;
      // TODO once scheduling is enabled, remove this block (and the text in localization files)
      if (onlineStatus === OnlineStatus.Offline) {
        return t('tooltips.disabledUpdateOfflineDevice');
      }

      if (updateInProgress) return t('tooltips.disabledUpdateInProgress');

      if (!userIsPatchManagementCapable) {
        return t('tooltips.disabledUpdateNotPremium');
      }

      return null;
    },
    [updateInstallCapabilities, userIsPatchManagementCapable]
  );
}
