import { ReactElement } from 'react';
import styles from './UpToDate.module.scss';
import { EmptyState } from '@getgo/chameleon-web-react-wrapper';
import { t } from '../../../i18n/i18n';
import { CheckmarkCircleFilledIcon } from '@getgo/chameleon-icons/react/';

export function UpToDate(): ReactElement {
  return (
    <div className={styles.container}>
      <>
        <EmptyState
          icon={<CheckmarkCircleFilledIcon color="var(--goto-icon-color-success)" />}
          title={t('upToDate.subtext')}>
          {t('upToDate.description')}
        </EmptyState>
      </>
    </div>
  );
}
