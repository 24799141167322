import useSwr from 'swr';
import http from '../../core/services/http/default-http-client';
import { environment } from '../../environments/environment';
import { combineUrl } from '../../core/utilities/utilities';
import logger from '../../core/services/logger.service';
import { policyListCacheKey } from '../models/cache';

export interface PolicyHistory {
  id: string;
  name: string;
  jobIds: string[];
}

export const useUpdatePolicies = (): { policies: PolicyHistory[]; error: any; loading: boolean } => {
  const { error, data, isLoading } = useSwr(
    policyListCacheKey,
    async () =>
      await http.get<unknown>(
        combineUrl(environment.policyServiceApiUrl, 'policies?path=/policy/patchManagement/windows/windowsUpdate/v1')
      )
  );

  if (error) {
    logger.logError(error as Error);
  }

  if (!data) {
    return { policies: [], loading: isLoading, error };
  }
  // policy.automationDefinitions[5].actions[0].moduleCommand.arguments.values[0].dataRow

  const jobHistory: PolicyHistory[] = (data as any[])
    .map(toParsedAutomationDefinition)
    .map(toFilteredJobHistoryDefinitions)
    .filter(policy => policy.automationDefinitions.length > 0)
    .map(toJobHistoryList);

  return { policies: jobHistory, loading: isLoading, error };
};

function toParsedAutomationDefinition(policy: any): any {
  return { id: policy.id, name: policy.name, automationDefinitions: JSON.parse(policy.automationDefinitions) };
}

function toFilteredJobHistoryDefinitions(policy: any): any {
  return {
    ...policy,
    automationDefinitions: policy.automationDefinitions.filter((definition: any) => definition.id.includes('reJob'))
  };
}

function toJobHistoryList(policy: any): PolicyHistory {
  const dataRow = policy.automationDefinitions[0].actions[0].moduleCommand.arguments.values[0];
  return {
    id: policy.id,
    name: policy.name,
    jobIds: [dataRow.to.reJobId, ...dataRow.to.previousReJobIds]
  };
}
