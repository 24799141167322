import { inject, injectable } from 'inversify';
import { z } from 'zod';
import { HttpServices } from '../../core/services/http/module';
import type { AxiosInstance } from 'axios';
import { AxiosError } from 'axios';
import ApiError from '../../core/services/http/ApiError';
import CompanyDeviceUpdateStatus from '../models/CompanyDeviceUpdateStatus';
import UpdateStatus from '../models/UpdateStatus';
import OnlineStatus from '../models/OnlineStatus';
import { AppInstallationState, AppUpdateState } from '../models/PatchManagementCapabilities';
import 'reflect-metadata';

@injectable()
export default class CompanyDeviceUpdatesService {
  constructor(@inject(HttpServices.FrontendApiAxios) private frontendApi: AxiosInstance) {}

  async getDeviceUpdateStatus(): Promise<CompanyDeviceUpdateStatus[]> {
    const response = await this.frontendApi.get('/updates/available').catch((e: AxiosError) => {
      throw new ApiError('Could not get device update status for the current company!', e);
    });
    const parsedResponse = companyDeviceUpdatesResponseSchema.parse(response.data);
    return parsedResponse.status.map(deviceStatusToModel);
  }
}

const deviceUpdatesStatusResponseSchema = z.object({
  updateStatus: z.enum(['UpToDate', 'UpdatesAvailable', 'Vulnerable', 'UpToDateWithOptionalUpdates', 'Unknown']),
  device: z.object({
    id: z.string(),
    name: z.string().nullable(),
    group: z
      .object({
        id: z.string(),
        name: z.string()
      })
      .nullable(),
    hostName: z.string().nullable(),
    onlineStatus: z.enum(['Online', 'Offline', 'InSession']),
    timeZone: z.string(),
    patchmanagement: z
      .object({
        appInstallationCapability: z
          .enum(['Available', 'UnknownError', 'WingetNotFound', 'WingetNotAccessible'])
          .nullable(),
        appUpdateCapability: z.enum(['Available', 'UnknownError', 'WingetNotFound', 'WingetNotAccessible']).nullable()
      })
      .nullish(),
    osActivation: z
      .enum([
        'SL_GEN_STATE_IS_GENUINE',
        'SL_GEN_STATE_INVALID_LICENSE',
        'SL_GEN_STATE_TAMPERED',
        'SL_GEN_STATE_OFFLINE',
        'SL_GEN_STATE_LAST',
        'Unknown'
      ])
      .nullish()
  }),
  updates: z.array(
    z.discriminatedUnion('type', [
      z.object({
        type: z.literal('windows-update'),
        id: z.string(),
        makesDeviceVulnerable: z.boolean(),
        isOptional: z.boolean(),
        requiresReboot: z.boolean()
      }),
      z.object({
        type: z.literal('winget-update'),
        appId: z.string(),
        targetVersion: z.string()
      })
    ])
  ),
  errorCodes: z.object({ windowsUpdate: z.string().nullable(), winget: z.string().nullable() }),
  lastScan: z.coerce.date().nullable(),
  lastScanOfWindowsUpdates: z.coerce.date().nullable().optional(),
  lastScanOfApplications: z.coerce.date().nullable().optional()
});

const companyDeviceUpdatesResponseSchema = z.object({
  status: z.array(deviceUpdatesStatusResponseSchema)
});

type DeviceUpdateStatusResponse = z.infer<typeof deviceUpdatesStatusResponseSchema>;

function deviceStatusToModel(deviceStatus: DeviceUpdateStatusResponse): CompanyDeviceUpdateStatus {
  return {
    ...deviceStatus,
    updateStatus: UpdateStatus[deviceStatus.updateStatus],
    device: {
      ...deviceStatus.device,
      onlineStatus: OnlineStatus[deviceStatus.device.onlineStatus],
      name: deviceStatus.device.name === null ? deviceStatus.device.hostName : deviceStatus.device.name,
      patchmanagement: {
        appInstallationCapability: deviceStatus.device.patchmanagement?.appInstallationCapability
          ? AppInstallationState[deviceStatus.device.patchmanagement?.appInstallationCapability]
          : undefined,
        appUpdateCapability: deviceStatus.device.patchmanagement?.appUpdateCapability
          ? AppUpdateState[deviceStatus.device.patchmanagement?.appUpdateCapability]
          : undefined
      },
      osActivation:
        deviceStatus.device.osActivation == undefined
          ? undefined
          : deviceStatus.device.osActivation === 'SL_GEN_STATE_IS_GENUINE'
    }
  };
}
