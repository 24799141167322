import { Container } from 'inversify';
import { EnvironmentModule } from './environments/module';
import { AuthModule } from './core/services/auth/module';
import { HttpModule } from './core/services/http/module';
import { UpdatesModule } from './windows-updates/services/module';

const patchManagementContainer = new Container({ skipBaseClassChecks: true });
patchManagementContainer.load(EnvironmentModule, AuthModule, HttpModule, UpdatesModule);

export default patchManagementContainer;
