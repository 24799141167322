import DeviceDetails from '../../windows-updates/components/DeviceDetails/DeviceDetails';
import JobDeviceUpdates from '../../windows-updates/components/job-device-updates/JobDeviceUpdates';
import UpdateDetails from '../../windows-updates/components/update-details/UpdateDetails';
import { ReactElement } from 'react';
import ScheduleUpdatesPage from '../../patch-view/pages/schedule-updates-wizard/ScheduleUpdatesWizard';
import { PatchManagementOverview } from '../../patch-view/pages/patch-management-overview/PatchManagementOverview';

export type GoToResolveAppRoute =
  | { path: '/alerts'; params?: never }
  | { path: '/devices'; params?: never }
  | { path: '/devices/individual-devices?filters=:filters'; params?: { filters: string } }
  | { path: '/devices/details/:deviceId'; params: { deviceId: string } }
  | { path: '/devices/manage-devices'; params?: never }
  | {
      path: '/helpdesk/services/:serviceId/incidents/edit/:referenceId';
      params: { serviceId: string; referenceId: string };
    }
  | { path: '/remote-execution'; params?: never }
  | { path: '/remote-execution/runs/:runId/details'; params: { runId: string } };

export type AppRoute =
  | { path: '/'; params?: never }
  | { path: '/:activeTab'; params?: { activeTab: string } }
  | { path: '/devices'; params?: never }
  | { path: '/devices/:deviceId/updates'; params: { deviceId: string } }
  | { path: '/devices/:deviceId/updates/:activeTab'; params: { deviceId: string; activeTabId: string } }
  | { path: '/updates'; params?: never }
  | { path: '/updates/:updateJobId'; params: { updateJobId: string } }
  | {
      path: '/updates/:jobId/devices/:deviceId/updates';
      params: { jobId: string; deviceId: string };
    }
  | {
      path: '/updates/:jobId/devices/:deviceId/updates/:activeTab';
      params: { jobId: string; deviceId: string; activeTab: string };
    }
  | { path: '/windows-updates'; params?: never }
  | { path: '/history'; params?: never }
  | { path: '/schedule'; params?: never };

export type AppRoutePattern =
  | '/'
  | '/updates'
  | '/devices'
  | '/devices/:deviceId/updates/:activeTab'
  | '/devices/:deviceId/updates/*'
  | '/windows-updates'
  | '/updates/:updateJobId'
  | '/updates/:jobId/devices/:deviceId/updates'
  | '/updates/:jobId/devices/:deviceId/updates/os-jobs-history'
  | '/updates/:jobId/devices/:deviceId/updates/app-jobs-history'
  | '/history'
  | '/schedule/*'
  | '*';

type RouteObject = { path: AppRoutePattern; element: ReactElement };

export const routes: ReadonlyArray<RouteObject> = [
  { path: '/', element: <PatchManagementOverview /> },
  { path: '/devices/:deviceId/updates/*', element: <DeviceDetails /> },
  { path: '/devices/:deviceId/updates/:activeTab', element: <DeviceDetails /> },
  { path: '/updates/:updateJobId', element: <UpdateDetails /> },
  { path: '/updates/:jobId/devices/:deviceId/updates', element: <JobDeviceUpdates /> },
  { path: '/updates/:jobId/devices/:deviceId/updates/os-jobs-history', element: <JobDeviceUpdates /> },
  { path: '/updates/:jobId/devices/:deviceId/updates/app-jobs-history', element: <JobDeviceUpdates /> },
  { path: '/schedule/*', element: <ScheduleUpdatesPage /> },
  { path: '*', element: <PatchManagementOverview /> }
];
