import { inject, injectable } from 'inversify';
import { GraphQLClient } from 'graphql-request';
import { getDeviceIds, getDevices } from '../../schema/graphql';
import { EnvironmentServices } from '../../../environments/module';
import type EnvironmentConfig from '../../../environments/EnvironmentConfig';
import { Device } from 'devices-component/dist/services/common-device-service';

@injectable()
export class CommonDeviceDataService {
  private graphqlClient: GraphQLClient;

  constructor(@inject(EnvironmentServices.EnvironmentConfig) private environment: EnvironmentConfig) {
    this.graphqlClient = new GraphQLClient('https://' + this.environment.commonDeviceServiceUrl + '/graphql');
  }

  setToken(token: string): void {
    this.graphqlClient.setHeader('authorization', `Bearer ${token}`);
  }

  setEndpoint(endpoint: string): void {
    this.graphqlClient.setEndpoint(endpoint);
  }

  async getDeviceIds(): Promise<Device['id'][]> {
    const data = await this.graphqlClient.request<{ getDevices: string[] }>(getDeviceIds);
    return data.getDevices;
  }

  async getDevices(deviceIds: Device['id'][]): Promise<Device[]> {
    const data = await this.graphqlClient.request<{ getDevices: Device[] }>(getDevices, { ids: deviceIds });
    return data.getDevices;
  }
}
