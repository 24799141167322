import {
  GotoResolveApp,
  Job,
  Platform,
  RunDto,
  ScanForUpdatesStep,
  RemoteExecutionService as UpstreamRemoteExecutionService
} from '@goto/remote-execution';
import { UiFrameAdapter, DefaultUiFrameAdapter, MockUiFrameAdapter } from 'goto-zero-trust-vault';
import logger from '../../core/services/logger.service';
import { environment } from '../../environments/environment';
import i18n from '../../i18n/i18n';
import Device from '../../core/models/Device';

export class RemoteExecutionService {
  private readonly remoteExecutionService: UpstreamRemoteExecutionService;

  constructor(userId: string, companyId: string, jwt: string) {
    const uiFrameAdapter: UiFrameAdapter = environment.production
      ? new DefaultUiFrameAdapter()
      : new MockUiFrameAdapter(companyId, userId, () => jwt);

    const options = {
      zeroTrustApiUrl: environment.zeroTrustApiUrl,
      remoteExecutionFrontendApiUrl: environment.remoteExecutionFrontendApiUrl
    };

    this.remoteExecutionService = UpstreamRemoteExecutionService.create(uiFrameAdapter, logger, options);
  }

  async run(job: Job): Promise<RunDto> {
    if (!this.remoteExecutionService) {
      const error = new Error('REService is not instantiated.');
      logger.logError(error);
      throw error;
    }

    try {
      return await this.remoteExecutionService.run(job, i18n.t('zeroTrust.install'));
    } catch (error) {
      logger.logError(error as Error);
      throw error;
    }
  }

  async createScanForUpdatesIpcJob(device: Device): Promise<RunDto> {
    const scanForUpdatesStep: ScanForUpdatesStep = {
      type: 'scanForUpdates',
      arguments: [],
      version: 1
    };

    const scanForUpdatesIpcJob: Job = {
      displayName: 'Scan for updates',
      steps: [scanForUpdatesStep],
      devices: [
        { hostId: device.id, hostName: device.hostName ?? '', name: device.name ?? '', timeZone: device.timeZone }
      ],
      platform: Platform.Windows,
      app: GotoResolveApp.PatchManagement
    };

    return await this.run(scanForUpdatesIpcJob);
  }
}
