import { useEffect, useState } from 'react';
import { useInjection } from 'inversify-react';
import { CommonDeviceDataService } from './CommonDeviceDataService';
import { useAppSelector } from '../../../core/store/root.store';
import { selectJwt } from '../../../ui-frame/state/ui-frame.slice';

type UseCommonDeviceDataServiceReturnType = {
  commonDeviceDataService: CommonDeviceDataService;
  isCommonDeviceDataServiceReady: boolean;
};

const useCommonDeviceDataService = (): UseCommonDeviceDataServiceReturnType => {
  const commonDeviceDataService = useInjection(CommonDeviceDataService);
  const token = useAppSelector(selectJwt);
  const [isCommonDeviceDataServiceReady, setIsCommonDeviceDataServiceReady] = useState(false);

  useEffect(() => {
    if (token) {
      commonDeviceDataService.setToken(token);
      setIsCommonDeviceDataServiceReady(true);
    }
  }, [token, commonDeviceDataService]);

  return { commonDeviceDataService, isCommonDeviceDataServiceReady };
};

export default useCommonDeviceDataService;
