import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../core/store/root.store';
import { SelectedDeviceIds } from '../components/devices-selector/devices-grid/DevicesGrid';
import { AdaptedDevice } from 'devices-component/types/adapted-device';

export interface DevicesSelectorState {
  selectedDeviceIds: SelectedDeviceIds;
  devices: AdaptedDevice[];
}

const initialState: DevicesSelectorState = {
  selectedDeviceIds: {
    deviceIds: [],
    groupIds: []
  },
  devices: []
};

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setSelectedDeviceIds: (state, action: PayloadAction<SelectedDeviceIds>) => {
      state.selectedDeviceIds = action.payload;
    },
    setDevices: (state, action: PayloadAction<AdaptedDevice[]>) => {
      state.devices = action.payload;
    }
  }
});

export const selectSelectedDeviceIds = (state: RootState): SelectedDeviceIds => state.devices.selectedDeviceIds;
export const selectDevices = (state: RootState): AdaptedDevice[] => state.devices.devices;
export const selectSelectedDevices = createSelector(
  [selectSelectedDeviceIds, selectDevices],
  (selectedDevices, devices) => {
    if (selectedDevices.deviceIds && selectedDevices.deviceIds.length > 0) {
      return selectedDevices.deviceIds
        .map(deviceId => devices.find(device => device.id === deviceId))
        .filter((device): device is AdaptedDevice => device !== undefined);
    }

    if (selectedDevices.groupIds && selectedDevices.groupIds.length > 0) {
      return selectedDevices.groupIds
        .map(groupId => devices.find(device => device.deviceGroupId === groupId))
        .filter((device): device is AdaptedDevice => device !== undefined);
    }

    return [];
  }
);

export const { setSelectedDeviceIds, setDevices } = devicesSlice.actions;

export const { reducer: devicesReducer } = devicesSlice;
