import { Button, Chip } from '@getgo/chameleon-web-react-wrapper';
import { ReactElement } from 'react';
import { ColumnFiltersState } from '@tanstack/react-table';
import { AddOutlinedIcon } from '@getgo/chameleon-icons/react';
import { useTranslation } from 'react-i18next';
import styles from './JobHistoryFilterHeader.module.scss';

interface Props {
  columnFilters: ColumnFiltersState;
  setColumnFilters: (filters: ColumnFiltersState) => void;
  setIsFilterPanelOpen: (isOpen: boolean) => void;
}

export default function JobHistoryFilterHeader({
  columnFilters,
  setColumnFilters,
  setIsFilterPanelOpen
}: Props): ReactElement {
  const { t } = useTranslation();

  function updateFilterValue(filterId: string, value: string[]): void {
    if (value.length === 0) {
      setColumnFilters(columnFilters.filter(filter => filter.id !== filterId));
      return;
    }

    setColumnFilters(
      columnFilters.map(filter => {
        if (filter.id === filterId) {
          return { ...filter, value };
        }

        return filter;
      })
    );
  }

  function translatedFilterValue(columnId: string, value: string): string | undefined {
    if (columnId === 'hostRunStatusCounts') {
      return t<any>(`updateDetails.grid.deviceJobStatus.${value}.title`);
    }

    if (columnId === 'jobStatus') {
      return t<any>(`jobHistoryGrid.groups.${value}`);
    }

    if (columnId === 'updateCount') {
      return t<any>(`jobHistoryGrid.updates.${value}`);
    }
  }

  function isFilterClear(): boolean {
    return columnFilters.every(f => !f.value);
  }

  function FilterButton(): ReactElement {
    if (isFilterClear()) {
      return (
        <Button
          className={styles.filterButton}
          variant="tertiary"
          leadingIcon={<AddOutlinedIcon />}
          onClick={() => setIsFilterPanelOpen(true)}>
          {t('jobHistoryGrid.addFilter')}
        </Button>
      );
    }

    return (
      <Button className={styles.filterButton} variant="tertiary" onClick={() => setIsFilterPanelOpen(true)}>
        {t('jobHistoryGrid.openFilter')}
      </Button>
    );
  }
  return (
    <>
      <FilterButton></FilterButton>
      {columnFilters.map(filter => {
        if (Array.isArray(filter.value)) {
          return filter.value.map(value => (
            <Chip
              key={value}
              className={styles.filterChip}
              closable
              onClose={() =>
                updateFilterValue(
                  filter.id,
                  (filter.value as string[]).filter((f: string) => f !== value)
                )
              }>
              {t<any>(`jobHistoryGrid.headers.${filter.id}`)}: {translatedFilterValue(filter.id, value)}
            </Chip>
          ));
        }

        return (
          <Chip
            key={filter.toString()}
            className={styles.filterChip}
            closable
            onClose={() => setColumnFilters(columnFilters.filter(f => filter.id !== f.id))}>
            {t<any>(`jobHistoryGrid.headers.${filter.id}`)}: {filter.value as string}
          </Chip>
        );
      })}
    </>
  );
}
