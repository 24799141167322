import WindowsUpdateSeverity from '../../../windows-updates/models/WindowsUpdateSeverity';

type DeviceGroupWithCount = {
  id: string;
  companyId: string;
  devicesCount: number;
};

export type WindowsUpdatesCategory = {
  uuid: string;
  type: string;
  title?: string;
  description?: string | null;
};

export type Update = {
  uuid: string;
  id: string;
  title: string;
  description: string;
  kbArticleId: number | string | null;
  mandatory: boolean;
  requiresReboot: boolean;
  availableOnDevicesCount: number;
  categories: WindowsUpdatesCategory[];
  software?: {
    id: string;
    name: string;
    vendor: string;
  };
  availableForGroups: DeviceGroupWithCount[];
  severity: WindowsUpdateSeverity;
};

export type Pagination = {
  offset: number;
  limit: number;
};

export enum SortDirection {
  Ascending = 'Ascending',
  Descending = 'Descending'
}

export enum UpdateSortField {
  Id = 'id',
  Severity = 'severity',
  Title = 'title'
}

export type UpdateSortCriterion = {
  field: UpdateSortField;
  direction: SortDirection;
};

export type AvailableUpdatesResponse = {
  availableUpdates: Update[];
};

export type TotalAvailableUpdatesCountResponse = {
  totalAvailableUpdatesCount: number;
};
