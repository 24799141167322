import { ReactElement } from 'react';
import styles from './DevicesSelector.module.scss';
import DevicesGrid, { SelectedDeviceIds } from './devices-grid/DevicesGrid';
import { UpdateRow } from '../../types/frontend/update-row';

type DevicesSelectorProps = {
  selectedUpdates: UpdateRow[];
  bufferedDeviceIdSelections?: SelectedDeviceIds;
  setBufferedDeviceIdSelections: (selections: SelectedDeviceIds) => void;
};

export function DevicesSelector(props: DevicesSelectorProps): ReactElement {
  return (
    <div className={styles.devicesSelectorContainer}>
      <DevicesGrid {...props} />
    </div>
  );
}

export default DevicesSelector;
