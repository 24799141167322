import { PropsWithChildren, ReactElement } from 'react';
import { AppLinkDef } from '../../models/AppLinkDef';
import Breadcrumbs from './Breadcrumbs';
import styles from './index.module.scss';

interface Props {
  title: ReactElement;
  breadcrumbLinks?: (AppLinkDef | null)[];
  actions?: ReactElement;
  badges?: ReactElement;
  alerts?: ReactElement;
}

function ContentHeader({ title, breadcrumbLinks, actions, badges, alerts }: PropsWithChildren<Props>): ReactElement {
  return (
    <div>
      <div className={styles.wrapper}>
        <Breadcrumbs links={breadcrumbLinks ?? []} />
        {actions}
      </div>
      <div className={styles.title}>
        {title}
        <div className={styles.badges}>{badges}</div>
      </div>
      {alerts && <div className={styles.alerts}>{alerts}</div>}
    </div>
  );
}

export default ContentHeader;
