import { TabsComponent } from '@getgo/chameleon-web';
import { TabPanel, Tabs, Typography } from '@getgo/chameleon-web-react-wrapper';
import { ChangeEvent, Fragment, ReactElement } from 'react';
import WindowsUpdatesSelector from '../../components/windows-updates-selector/WindowsUpdatesSelector';
import styles from './PatchManagementOverview.module.scss';
import { TrackedTab } from '../../../core/components/tracking/TrackedComponents';
import { PatchManagementOverviewTabSwitchedEvent } from '../../../core/models/UserTrackingEvents';
import { upperCaseFirstLetter } from '../../../core/utilities/utilities';
import { t } from '../../../i18n/i18n';
import JobHistoryGrid from '../../components/job-history/JobHistoryGrid';
import CompanyUpdatesOverview from '../../../windows-updates/components/CompanyUpdatesOverview/CompanyUpdatesOverview';
import clsx from 'clsx';
import ContentHeader from '../../../core/components/ContentHeader';
import { useAppSelector } from '../../../core/store/root.store';
import { selectNavigationRoute } from '../../../ui-frame/state/ui-frame.slice';
import { navigate } from '../../../core/services/navigation.service';
import { TabDefinition } from '../../types/frontend/tabs-definition';

const TABS: TabDefinition<OverviewTabs>[] = [
  { tabId: 'windows updates', locationSuffix: '/windows-updates', component: <WindowsUpdatesSelector /> },
  {
    tabId: 'devices',
    locationSuffix: '/devices',
    component: <CompanyUpdatesOverview />
  },
  { tabId: 'history', locationSuffix: '/history', component: <JobHistoryGrid /> }
];

export type OverviewTabs = 'windows updates' | 'devices' | 'history';

export function PatchManagementOverview(): ReactElement {
  const navigationRoute = useAppSelector(selectNavigationRoute);
  const hideDevicesTab = useAppSelector(state => state.uiFrame.options.hideDevicesTab);

  const activeTabId = ['/windows-updates', '/devices', '/history'].find(locationSuffix => {
    return navigationRoute.includes(locationSuffix);
  });

  const onTabChange = (event: ChangeEvent<TabsComponent>): void => {
    const newActiveTabId = event.currentTarget.activetab.id;

    if (newActiveTabId === activeTabId) {
      return;
    }
    navigate({ path: `/:activeTab`, params: { activeTab: newActiveTabId } });
  };

  return (
    <div>
      <ContentHeader
        title={
          <>
            <Typography variant="heading-medium" tag="h1">
              {t('updatesOverviewPage.title')}
            </Typography>
          </>
        }
      />
      <Tabs className={styles.tabs} activeid={activeTabId} label="Tabs label" onChange={onTabChange}>
        {TABS.filter(tab => {
          if (tab.tabId === 'devices' && hideDevicesTab === 'true') {
            return false;
          }
          return true;
        }).map(tab => (
          <Fragment key={tab.tabId}>
            <TrackedTab
              trackingEvent={new PatchManagementOverviewTabSwitchedEvent(tab.tabId)}
              key={tab.tabId}
              id={tab.locationSuffix}>
              {upperCaseFirstLetter(tab.tabId)}
            </TrackedTab>
            <TabPanel className={clsx(activeTabId === '/devices' && styles.deviceUpdatesContainer)} id={tab.tabId}>
              {tab.component}
            </TabPanel>
          </Fragment>
        ))}
      </Tabs>
    </div>
  );
}
