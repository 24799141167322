import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CompanyUpdatesOverview.module.scss';
import Tooltip from '../../../core/components/tooltips/Tooltip';
import { InfoOutlinedIcon } from '@getgo/chameleon-icons/react';
import ExternalLink from '../../../core/components/ExternalLink';
import DeviceUpdateStatusGrid from './DeviceUpdateStatusGrid/DeviceUpdateStatusGrid';
import CompanyDeviceUpdateStatus from '../../models/CompanyDeviceUpdateStatus';
import CompanyDeviceUpdateDrawer from '../UpdateDrawer/CompanyDeviceUpdateDrawer';
import GridFilterIds from '../../models/GridFilterIds';
import { navigate } from '../../../core/services/navigation.service';
import { selectNavigationRoute } from '../../../ui-frame/state/ui-frame.slice';
import { useAppSelector } from '../../../core/store/root.store';

const FILTER_FRAGMENTS = [GridFilterIds.ALL, GridFilterIds.VULNERABLE_DEVICES, GridFilterIds.CRITICAL_UPDATES];

export type OverviewTabs = 'patches' | 'history';

export default function CompanyUpdatesOverview(): ReactElement {
  const { t } = useTranslation();
  const [selectedDeviceUpdates, setSelectedDeviceUpdates] = useState<CompanyDeviceUpdateStatus[]>([]);
  const [installRequestPending, setInstallRequestPending] = useState(false);

  const navigationRoute = useAppSelector(selectNavigationRoute);

  const activeFilterId = FILTER_FRAGMENTS.find(id => {
    return navigationRoute.includes(id);
  });

  const onActiveFilterChange = (currentFilter: GridFilterIds): void => {
    if (currentFilter === activeFilterId) {
      return;
    }

    const path = (
      activeFilterId ? navigationRoute.replace(activeFilterId, currentFilter) : `${navigationRoute}${currentFilter}`
    ) as '/devices';

    navigate({ path });
  };

  const selectedDeviceIds = useMemo(() => {
    const selectedDeviceIds = new Set<string>();
    selectedDeviceUpdates.forEach(deviceUpdate => {
      selectedDeviceIds.add(deviceUpdate.device.id);
    });
    return selectedDeviceIds;
  }, [selectedDeviceUpdates]);

  return (
    <>
      <div className={styles.installInfoWrapper}>
        <div className={styles.installInfo}>
          <Tooltip
            position={'bottom-end'}
            max-width="300"
            trigger={
              <ExternalLink
                to="https://support.goto.com/resolve/help/working-with-windows-updates-in-goto-resolve"
                variant={'body-small'}>
                {t('companyUpdatesOverview.installInfo.link')}
              </ExternalLink>
            }>
            {t('companyUpdatesOverview.installInfo.tooltip')}
          </Tooltip>
          <Tooltip
            position={'bottom-end'}
            max-width="300"
            trigger={<InfoOutlinedIcon class={styles.installInfoIcon} />}>
            {t('companyUpdatesOverview.installInfo.tooltip')}
          </Tooltip>
        </div>
      </div>

      <DeviceUpdateStatusGrid
        selectedDeviceIds={selectedDeviceIds}
        onDeviceSelectionChange={setSelectedDeviceUpdates}
        installRequestPending={installRequestPending}
        initialFilter={activeFilterId ?? GridFilterIds.ALL}
        onFilterChanged={onActiveFilterChange}
      />

      {selectedDeviceUpdates.length > 0 && (
        <CompanyDeviceUpdateDrawer
          selectedDeviceUpdates={selectedDeviceUpdates.map(deviceUpdates => ({
            device: deviceUpdates.device,
            updates: deviceUpdates.updates
          }))}
          isInstallationPending={installRequestPending}
          setInstallationPending={setInstallRequestPending}
          onClose={() => setSelectedDeviceUpdates([])}
        />
      )}
    </>
  );
}
