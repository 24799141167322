import { inject, injectable } from 'inversify';
import type EnvironmentConfig from '../../environments/EnvironmentConfig';
import { EnvironmentServices } from '../../environments/module';
import { GraphQLClient } from 'graphql-request';
import { UpdateRow } from '../types/frontend/update-row';
import { PaginationState, SortingState } from '@tanstack/react-table';
import {
  AvailableUpdatesInput,
  TotalAvailableUpdatesCountInput,
  getAvailableUpdatesQuery,
  totalAvailableUpdatesCountQuery
} from '../schema/graphql';
import {
  AvailableUpdatesResponse,
  SortDirection,
  TotalAvailableUpdatesCountResponse,
  Update,
  UpdateSortField
} from '../types/backend/update';

export const backendSupportedLocales = ['en', 'de', 'es', 'fr', 'it'] as const;
export type Locale = (typeof backendSupportedLocales)[number];
export const isSupportedLocale = (locale?: string): locale is Locale =>
  backendSupportedLocales.includes(locale as Locale);

export type AvailableUpdates = { availableUpdates: UpdateRow[] };

export const SECURITY_UPDATE_CATEGORY_IDS = [
  [
    '8c3fcc84-7410-4a95-8b89-a166a0190486' // Windows Updates
  ],
  [
    '0fa1201d-4330-4fa8-8ae9-b877473b6441' // Security Updates
  ]
];

@injectable()
export class PatchviewDataService {
  private graphqlClient: GraphQLClient;

  constructor(@inject(EnvironmentServices.EnvironmentConfig) private environment: EnvironmentConfig) {
    this.graphqlClient = new GraphQLClient(this.environment.patchviewApiUrl);
  }

  setToken(token: string): void {
    this.graphqlClient.setHeader('authorization', `Bearer ${token}`);
  }

  setEndpoint(endpoint: string): void {
    this.graphqlClient.setEndpoint(endpoint);
  }

  async getAvailableUpdates(
    { pageIndex, pageSize }: PaginationState,
    sorting: SortingState,
    securityUpdatesOnly: boolean,
    searchText?: string,
    deviceIds?: string[],
    locale?: string
  ): Promise<AvailableUpdates> {
    const variables: AvailableUpdatesInput = {
      pagination: {
        offset: pageIndex * pageSize,
        limit: pageSize
      },
      ...(sorting[0] && {
        sort: [
          {
            direction: sorting[0].desc ? SortDirection.Descending : SortDirection.Ascending,
            field: sorting[0].id as UpdateSortField
          }
        ]
      }),
      ...(securityUpdatesOnly && {
        categoryIds: SECURITY_UPDATE_CATEGORY_IDS
      }),
      ...(searchText && { searchText }),
      deviceIds,
      preferredLocale: isSupportedLocale(locale) ? locale : 'en'
    };
    const { getAvailableUpdates } = await this.graphqlClient.request<{
      getAvailableUpdates: AvailableUpdatesResponse;
    }>(getAvailableUpdatesQuery, variables);

    const { availableUpdates } = getAvailableUpdates;

    // For the time being, we only support integer KB article IDs
    // See ticket NG-37121
    function transformUpdates(availableUpdates: Update[]): UpdateRow[] {
      return availableUpdates.map(update => ({
        ...update,
        kbArticleId:
          Number.isInteger(Number(update.kbArticleId)) && update.kbArticleId != null ? 'KB' + update.kbArticleId : null
      }));
    }

    return {
      availableUpdates: transformUpdates(availableUpdates)
    };
  }

  async getTotalAvailableUpdatesCount(
    securityUpdatesOnly: boolean,
    searchText?: string,
    deviceIds?: string[],
    locale?: string
  ): Promise<number> {
    const variables: TotalAvailableUpdatesCountInput = {
      ...(securityUpdatesOnly && {
        categoryIds: SECURITY_UPDATE_CATEGORY_IDS
      }),
      ...(searchText && { searchText }),
      deviceIds,
      preferredLocale: isSupportedLocale(locale) ? locale : 'en'
    };

    const { getAvailableUpdates } = await this.graphqlClient.request<{
      getAvailableUpdates: TotalAvailableUpdatesCountResponse;
    }>(totalAvailableUpdatesCountQuery, variables);

    return getAvailableUpdates.totalAvailableUpdatesCount;
  }
}
