import { ReactElement, useCallback, useEffect, useRef } from 'react';
import './DevicesGrid.module.scss';
import 'devices-component/components/tables/devices-selector-table';
import 'devices-component/components/tables/groups-selector-table';
import { useAppDispatch } from '../../../../core/store/root.store';
import { setDevices, setSelectedDeviceIds } from '../../../state/device-selector.slice';
import { DevicesSelectorTable } from '../../../types/frontend/devices-selector-table';
import { AdaptedDevice } from 'devices-component/types/adapted-device';
import { getDevices } from '../../../schema/graphql';
import { UpdateRow } from '../../../types/frontend/update-row';
import { useDevicesSelectorProps } from './useDevicesSelectorProps';

export type SelectedDeviceIds = {
  deviceIds?: string[];
  groupIds?: string[];
};

type SelectionChangedEventDetail = {
  deviceIds: string[];
  groupIds: string[];
  devices?: AdaptedDevice[];
};

type DevicesGridProps = {
  selectedUpdates: UpdateRow[];
  bufferedDeviceIdSelections?: SelectedDeviceIds;
  setBufferedDeviceIdSelections: (selections: SelectedDeviceIds) => void;
};

export function DevicesGrid({
  selectedUpdates,
  bufferedDeviceIdSelections,
  setBufferedDeviceIdSelections
}: DevicesGridProps): ReactElement {
  const dispatch = useAppDispatch();

  const devicesTableRef = useRef<HTMLDivElement>(null);
  const props = useDevicesSelectorProps(selectedUpdates);
  const handleSelectionChange = useCallback(
    (event: Event) => {
      const selectorTable: DevicesSelectorTable = devicesTableRef.current as any;
      const {
        detail: { deviceIds, groupIds }
      } = event as CustomEvent<SelectionChangedEventDetail>;
      dispatch(setDevices(selectorTable?.devices ?? []));
      dispatch(
        setSelectedDeviceIds({
          deviceIds: deviceIds.filter(x => x),
          groupIds: groupIds.filter(x => x)
        })
      );
    },
    [dispatch, devicesTableRef]
  );

  const handleDeviceLoaded = useCallback(() => {
    const selectorTable: DevicesSelectorTable = devicesTableRef.current as any;

    if (bufferedDeviceIdSelections?.deviceIds) {
      // manually set the selection from outside
      selectorTable.selectedDeviceIds = bufferedDeviceIdSelections.deviceIds;
      // clear buffer
      setBufferedDeviceIdSelections({});
    }

    dispatch(
      setSelectedDeviceIds({
        deviceIds: selectorTable.selectedDeviceIds,
        groupIds: selectorTable.selectedGroupIds.filter((x): x is string => {
          return typeof x === 'string' && x !== null;
        })
      })
    );
    dispatch(setDevices(selectorTable?.devices ?? []));
  }, [dispatch, devicesTableRef, bufferedDeviceIdSelections, setBufferedDeviceIdSelections]);

  useEffect(() => {
    const element = devicesTableRef.current;

    // on-change event is not triggered when filters are changed...
    element?.addEventListener('on-change', handleSelectionChange);
    // ...so we need to listen to devices-loaded event for selection updates
    element?.addEventListener('devices-loaded', handleDeviceLoaded);

    return () => {
      element?.removeEventListener('on-change', handleSelectionChange);
      element?.removeEventListener('devices-loaded', handleDeviceLoaded);
    };
  }, [dispatch, handleSelectionChange, handleDeviceLoaded]);

  return (
    <div>
      <devices-selector-table
        // default-checked-device-ids < not working, so we need the buffer workaround. if you read this, might as well check if it's fixed :)
        ref={devicesTableRef}
        query={getDevices}
        {...props}
      />
    </div>
  );
}

export default DevicesGrid;
