import { useMemo } from 'react';
import { useAppSelector } from '../../core/store/root.store';
import { t } from '../../i18n/i18n';
import { selectSelectedDeviceIds } from '../state/device-selector.slice';
import { selectSelectedUpdates } from '../state/windows-updates-selector-slice';
import { UpdateRow } from '../types/frontend/update-row';
import { SelectedDeviceIds } from '../components/devices-selector/devices-grid/DevicesGrid';
import { AdaptedDevice } from 'devices-component/types/adapted-device';

export const usePlaceholderText = (deviceFilteredSelectedUpdates: UpdateRow[], devices: AdaptedDevice[]): string => {
  const deviceCount = devices.length;
  const updatesCount = deviceFilteredSelectedUpdates.length;

  return useMemo(() => {
    if (deviceCount && updatesCount) {
      const updatesTextKey = updatesCount === 1 ? 'placeHolderStart_one' : 'placeHolderStart';
      const devicesTextKey = deviceCount === 1 ? 'devicesPlaceHolder_oneEnd' : 'devicesPlaceHolderEnd';
      const date = new Date();
      const formattedDate = date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
      return `${formattedDate} - ${date.getHours()}:${date.getMinutes()} - ${t(`reviewAndInstall.${updatesTextKey}`, {
        updates: updatesCount
      })} ${t(`reviewAndInstall.${devicesTextKey}`, { devices: deviceCount })}`;
    }
    return 'Missing devices or updates';
  }, [updatesCount, deviceCount]);
};
