import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store/root.store';

interface ManualDeviceScanHistoryState {
  manualDeviceScanHistory: Array<{ deviceId: string; lastScan: string }>;
}

const initialState: ManualDeviceScanHistoryState = {
  manualDeviceScanHistory: []
};

export const manualDeviceScanHistorySlice = createSlice({
  name: 'manualDeviceScanHistory',
  initialState,
  reducers: {
    addDeviceScan: (state, action: PayloadAction<{ deviceId: string; lastScan: string }>) => {
      const { deviceId, lastScan } = action.payload;
      const indexOfDeviceScan = state.manualDeviceScanHistory.findIndex(entry => entry.deviceId === deviceId);

      if (indexOfDeviceScan === -1) {
        state.manualDeviceScanHistory.push({ deviceId, lastScan });
      } else {
        state.manualDeviceScanHistory[indexOfDeviceScan].lastScan = lastScan;
      }
    },
    removeDeviceScan: (state, action: PayloadAction<string>) => {
      const deviceId = action.payload;
      state.manualDeviceScanHistory = state.manualDeviceScanHistory.filter(entry => entry.deviceId !== deviceId);
    }
  }
});

// selectors
export const selectManualDeviceScanHistory = (state: RootState): Array<{ deviceId: string; lastScan: string }> =>
  state.manualDeviceScanHistory.manualDeviceScanHistory;
export const { addDeviceScan, removeDeviceScan } = manualDeviceScanHistorySlice.actions;

export default manualDeviceScanHistorySlice.reducer;
