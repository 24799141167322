import WindowsUpdatesService from '../services/WindowsUpdatesService';
import useSwr, { KeyedMutator } from 'swr';
import { WindowsUpdatesByDeviceResponse } from '../models/windows-updates-by-device-response';
import { useInjection } from 'inversify-react';

const useDeviceWindowsUpdates = (
  deviceId: string
): {
  data?: WindowsUpdatesByDeviceResponse;
  error?: Error;
  mutate: KeyedMutator<WindowsUpdatesByDeviceResponse>;
  isLoading: boolean;
} => {
  const windowsUpdatesService = useInjection(WindowsUpdatesService);
  return useSwr([deviceId, 'WindowsUpdatesService', 'getDeviceUpdates'], ([deviceId]) => {
    return windowsUpdatesService.getDeviceUpdates(deviceId);
  });
};

export default useDeviceWindowsUpdates;
